import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Space,
  Spin,
  Tag,
  Upload,
  message,
  notification,
} from "antd";

import {
  createBatchedParsing,
  deleteParsing,
  getParsing,
  parsePdf,
} from "../../../services";

import {
  DeleteOutlined,
  FilePdfOutlined,
  LeftOutlined,
  RocketOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { Flex } from "../../../components/shared/global";
import LoadingLayout from "../../../components/shared/LoadingLayout";
import { IParsing } from "~/model/parser.model";
import { getRequestErrorMessage } from "~/utils/helpers";
import { ParsingStatusTag } from "~/components/parsing/ParsingStatusTag";
import { ParsingStatusEnum } from "~/model/enum";
import { ParsingResultCard } from "~/components/parsing/ParsingResultCard";
import { CreateParsingDoiForm } from "~/components/parsing/CreateParsingDoiForm";

export const ParsingEdition: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [innerParsing, setInnerParsing] = useState<IParsing>();
  const [innerBatchName, setInnerBatchName] = useState<string>("");
  const [innerFiles, setInnerFiles] = useState<File[]>([]);
  const [isParsing, setisParsing] = useState<boolean>(false);

  const parsingId = params.id;
  const parsingStatus = innerParsing?.meta?.status;

  const disableUpload =
    parsingStatus === ParsingStatusEnum.SUCCESS ||
    (!innerParsing?.pdfName && innerParsing?.doi);

  const fetchParsing = async () => {
    if (!parsingId || innerParsing) return;

    const parsing = await getParsing(parsingId);
    setInnerParsing(parsing);
  };

  const handleParsePdfFiles = async () => {
    const specialCharPattern = /[^a-zA-Z0-9-_ ]/;

    setisParsing(true);
    try {
      if (!innerFiles.length) return;

      if (innerFiles.length > 1) {
        if (!innerBatchName.length)
          throw new Error("Batch name is required for multiple files");

        if (specialCharPattern.test(innerBatchName))
          throw new Error("Batch name contains special characters");

        await createBatchedParsing(innerFiles, innerBatchName);
      } else {
        const formData = new FormData();
        formData.append("file", innerFiles[0]);
        await parsePdf(formData);
      }

      navigate(`/content-sources/parsing/`);
      notification.success({
        message: "Parsing created",
        description: "Parsing was successfully created",
      });
    } catch (error) {
      notification.error({
        message: "An error occurred while parsing",
        description: getRequestErrorMessage(error),
      });
    } finally {
      setisParsing(false);
    }
  };

  const handleDeletion = async () => {
    if (!parsingId) return message.error("Parsing ID not found");

    Modal.confirm({
      title: "Are you sure you want to delete this parsing?",
      content: "This action cannot be undone",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await deleteParsing(parsingId);
          navigate(-1);
          notification.success({
            message: "Parsing was successfully deleted",
            description: "The list will be updated in a few seconds",
          });
        } catch (error) {
          notification.error({
            message: "An error occurred while deleting parsing",
            description: getRequestErrorMessage(message),
          });
        }
      },
    });
  };

  useEffect(() => {
    fetchParsing();
  });

  if (!innerParsing && params.id) return <LoadingLayout />;

  return (
    <div className='basic-layout'>
      <Space direction='vertical' size='large' style={{ width: "100%" }}>
        <Button
          type='link'
          icon={<LeftOutlined />}
          onClick={() => navigate(-1)}
          style={{ padding: 0 }}
        >
          Back
        </Button>

        <Flex justify='space-between' align='center'>
          <div style={{ width: "100%" }}>
            <h1 style={{ fontWeight: 800, fontSize: "30px" }}>PDF Parser</h1>
            <h3 style={{ marginTop: 0 }}>
              {innerParsing?.article?.title ||
                innerParsing?.pdfName ||
                innerParsing?.doi}
            </h3>
          </div>
          {params.id ? (
            <Flex align='center' justify='end' gap={8}>
              <Tag
                style={{
                  padding: "6px 16px",
                  fontSize: 12,
                  margin: 0,
                }}
              >
                <Flex align='center' gap={8}>
                  <span>{innerParsing?.batchName ? "🗃️" : "📄"}</span>
                  {innerParsing?.batchName ? "BATCH" : "SINGLE"}
                </Flex>
              </Tag>

              {innerParsing && (
                <ParsingStatusTag size='large' parsing={innerParsing} />
              )}

              <Divider type='vertical' />

              <Button
                icon={<DeleteOutlined />}
                type='primary'
                danger
                onClick={handleDeletion}
              >
                {"Delete parsing"}
              </Button>
            </Flex>
          ) : null}
        </Flex>
        <div>
          {parsingId ? (
            <Row>
              <Col span={24}>
                <ParsingResultCard parsing={innerParsing} />
              </Col>
            </Row>
          ) : (
            <Row gutter={8}>
              <Col span={12}>
                <Card
                  style={{
                    minHeight: "100%",
                    opacity: !disableUpload ? 1 : 0.5,
                    pointerEvents: !disableUpload ? "all" : "none",
                  }}
                  title={
                    <h3>
                      <Space>
                        <FilePdfOutlined />
                        {"Parse from PDF documents"}
                      </Space>
                    </h3>
                  }
                >
                  <Flex flexDirection='column' gap={16}>
                    {(!params.id || innerParsing?.batchName) && (
                      <Input
                        disabled={innerFiles.length < 2}
                        defaultValue={innerParsing?.batchName || innerBatchName}
                        placeholder='Batch name'
                        onChange={(e) => setInnerBatchName(e.target.value)}
                      />
                    )}
                    {innerFiles.length > 1 && <Divider style={{ margin: 4 }} />}
                    <div className='full-width-upload-btn'>
                      <Upload
                        multiple={!params.id}
                        accept='.pdf'
                        customRequest={(payload) => {
                          if (payload.onSuccess) payload.onSuccess("ok");
                        }}
                        onChange={({ file, fileList }) => {
                          if (file.status === "error") {
                            message.error(`${file.name} file upload failed.`);
                          }

                          setInnerFiles(
                            fileList.map((file) => file.originFileObj as File)
                          );
                        }}
                      >
                        <Button
                          disabled={isParsing}
                          block
                          icon={<UploadOutlined />}
                        >
                          Upload Files
                        </Button>
                      </Upload>
                    </div>
                    {innerFiles.length > 0 && <Divider style={{ margin: 4 }} />}
                    {innerFiles.length > 0 && (
                      <Button
                        block
                        type='primary'
                        onClick={handleParsePdfFiles}
                        disabled={innerFiles.length === 0}
                        loading={isParsing}
                        icon={<RocketOutlined />}
                      >
                        {"Parse now!"}
                      </Button>
                    )}
                  </Flex>
                </Card>
              </Col>
              <Col span={12}>
                <Spin
                  indicator={<div />}
                  tip='Remove PDF files to use this section'
                  spinning={innerFiles.length > 0}
                >
                  <CreateParsingDoiForm />
                </Spin>
              </Col>
            </Row>
          )}
        </div>
      </Space>
    </div>
  );
};
