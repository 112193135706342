import { DownloadOutlined } from "@ant-design/icons";
import { Button, Divider, notification, Table } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { NectarFilterDrawer } from "~/components/nectar/NectarFilterDrawer";
import { Flex } from "~/components/shared/global";
import { GlobalContext } from "~/context/global.context";
import { useNectarColumns } from "~/hooks/nectars/use-nectar-columns";
import { useNectarContext } from "~/hooks/nectars/use-nectar-context";
import { useNectarsQuery } from "~/hooks/nectars/use-nectars-query";
import { SupportedLanguage } from "~/model/enum";
import { IQuery } from "~/model/query.model";
import { downloadNectarCsv } from "~/services";
import { getRequestErrorMessage } from "~/utils/helpers";

const FETCH_LIMIT = 20;
const INIT_FILTERS: IQuery = {
  sortBy: "creationDate",
  sortOrder: "desc",
  limit: FETCH_LIMIT,
  offset: 0,
};

export default function NectarList() {
  const { nectars, activeTab } = useNectarContext();
  const { highlightNectars: highlightList } = useContext(GlobalContext);
  const { fetch: fetchNectars, loading } = useNectarsQuery();
  const { columns } = useNectarColumns();

  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState<IQuery | null>({
    ...INIT_FILTERS,
    languages:
      (searchParams.get("languages") as unknown as SupportedLanguage[]) ||
      undefined,
  });
  const [selectedNectar, setSelectedNectar] = useState<string[]>([]);

  const handleChangeFilters = (values: IQuery) => {
    if (!values) setFilters(INIT_FILTERS);
    else setFilters({ ...filters, ...values });
  };

  const handleDownloadNectarCsv = async () => {
    try {
      const nectarCsv = await downloadNectarCsv(selectedNectar);
      const blobConfig = new Blob([nectarCsv], {
        type: "text/csv;charset=utf-8",
      });
      const blobUrl = URL.createObjectURL(blobConfig);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = "nectars.csv";

      document.body.appendChild(link);
      link.click();

      URL.revokeObjectURL(blobUrl);
      document.body.removeChild(link);
    } catch (error) {
      notification.error({
        message: "Failed to download nectar CSV",
        description: getRequestErrorMessage(error),
      });
    }
  };

  const handleCheckNectar = (selectedRowKeys: React.Key[]) => {
    const nectarIds = nectars?.docs.map((el) => el._id);

    const selection = [
      ...(selectedRowKeys as string[]),
      ...selectedNectar.filter((el) => !nectarIds?.includes(el)),
    ];

    setSelectedNectar(selection);
  };

  const handleChangePage = useCallback(
    (page: number, pageSize?: number) => {
      setFilters({
        ...filters,
        offset: (page - 1) * (pageSize || FETCH_LIMIT),
        limit: pageSize || FETCH_LIMIT,
      });
    },
    [filters]
  );

  const saveLanguageFilterInUrl = useCallback(() => {
    if (!filters?.languages || !filters.languages?.length)
      searchParams.delete("languages");
    else searchParams.set("languages", filters.languages as unknown as string);

    setSearchParams(searchParams);
  }, [filters, searchParams, setSearchParams]);

  useEffect(() => {
    if (!filters || loading) return;

    fetchNectars(filters);

    saveLanguageFilterInUrl();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <div>
      <Flex align='center' justify='space-between' gap={64}>
        <h1 style={{ fontWeight: 800, fontSize: "30px", margin: 0 }}>
          {activeTab === "highlights" ? "Highlighted Nectars" : "Nectars"}
        </h1>
        <Flex gap={16} align='center' justify='end'>
          <NectarFilterDrawer
            defaultValues={filters || undefined}
            onChange={handleChangeFilters}
          />
          <Divider type='vertical' />
          <Button
            type='primary'
            icon={<DownloadOutlined />}
            onClick={handleDownloadNectarCsv}
            disabled={!selectedNectar.length}
          >
            {"Download CSV"}
          </Button>
        </Flex>
      </Flex>
      <Divider />

      <Table
        size='small'
        scroll={{ x: "max-content", y: "calc(100vh - 300px)" }}
        pagination={{
          current: filters?.offset
            ? filters.offset / (filters?.limit || FETCH_LIMIT) + 1
            : 1,
          pageSize: filters?.limit || FETCH_LIMIT,
          pageSizeOptions: [10, 25, 50, 100],
          total: nectars?.meta.total,
          showSizeChanger: true,
          onChange: (page, pageSize) => handleChangePage(page, pageSize),
        }}
        rowSelection={{
          type: "checkbox",
          selectedRowKeys: selectedNectar,
          onChange: handleCheckNectar,
        }}
        columns={columns}
        loading={!nectars}
        dataSource={
          nectars
            ? nectars.docs
                .map((nectar) => ({
                  key: nectar._id,
                  ...nectar,
                  highlight: !!highlightList?.some((el) =>
                    el.highlight.find((el) => el.nectarId === nectar._id)
                  ),
                }))
                .filter((el) =>
                  activeTab === "highlights" ? el.highlight : !el.highlight
                )
            : []
        }
      />
    </div>
  );
}
