import React, { useContext, useEffect, useState } from "react";
import { GlobalContext, IGlobalContext } from "../context/global.context";
import {
  Button,
  Col,
  Form,
  Layout,
  Pagination,
  Row,
  Select,
  Spin,
  Table,
  Tag,
} from "antd";
import { listVideos, searchVideos } from "../services";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  BarChartOutlined,
  UnorderedListOutlined,
  VideoCameraAddOutlined,
} from "@ant-design/icons";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import FiltersDrawer, {
  FiltersDrawerValuesModel,
} from "./shared/FiltersDrawer";
import { useCheckFiltersSaved } from "~/utils/hooks";
import { searchFilterOptions } from "~/utils/helpers";
import { LocalStorageKeys } from "~/utils/constants";
import { IQuery, ISorter } from "~/model/query.model";
import { IVideo } from "~/model/video.model";
import { IOrganisation } from "~/model/organisation.model";
import { ICompany } from "~/model/company.model";
import { IJournal } from "~/model/journal.model";
import { ITag } from "~/model/tag.model";

export const VideoList: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { companyList, organisationList, tagList } = useContext(
    GlobalContext
  ) as IGlobalContext;
  const [innerVideoList, setInnerVideoList] = useState<IVideo[]>([]);
  const [searchList, setSearchList] = useState<IVideo[]>([]);
  const [filters, setFilters] = useState<FiltersDrawerValuesModel>();
  const [sorter, setSorter] = useState<ISorter>();
  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [videoFormat, setVideoFormat] = useState<
    "story" | "article" | undefined
  >();

  const hasSavedFilters = useCheckFiltersSaved();

  const [metricsMode, setMetricsMode] = useState<boolean>(false);

  const fetchVideos = async (limit?: number, offset?: number) => {
    const medical_specialties = tagList.find((tag) =>
      filters?.medicalSpecialties?.includes(tag._id)
    )?.uid;

    if (!filters && hasSavedFilters) return; // Prevent fetching videos if filters are saved

    // delete filters?.medicalSpecialties;

    const query: IQuery = {
      ...sorter,
      ...filters,
      content_format: "Video",
      limit: limit || 100,
      offset: offset || 0,
      medical_specialties,
      videoFormat,
    };

    const videoList = await listVideos(query);
    setIsLoading(true);

    if (videoList) {
      setInnerVideoList(videoList.docs);
      setCount(videoList.meta.total);
    }
    setIsLoading(false);
  };

  const handleSearch = async (searchValue: string) => {
    if (!searchValue.length) {
      setSearchList([]);
      return;
    }
    setIsLoading(true);
    const { hits } = await searchVideos(searchValue, {
      ...sorter,
      ...filters,
      videoFormat,
    });

    const videos = hits.map((hit) => ({
      ...hit._source.view,
      key: hit._source.id,
      owner: organisationList.find(
        (org) => org._id === (hit._source.filters?.owner as unknown as string)
      ),
    }));

    setSearchList(videos);
    setIsLoading(false);
  };

  const handlePaginationChange = async (page: number, pageSize?: number) => {
    if (pageSize) {
      await fetchVideos(
        pageSize,
        (page - 1) * pageSize < 0 ? 0 : (page - 1) * pageSize
      );
    }
    setPage(page);
  };

  const handleChangeFilters = (filters: FiltersDrawerValuesModel) => {
    setFilters(filters);
    handlePaginationChange(1);
  };

  const handleTableChange = (
    _pagination: TablePaginationConfig,
    _filters: any,
    sorter: any
  ) => {
    const sortOrder = sorter.order === "ascend" ? "asc" : "desc";

    if (sorter.column?.title === "Creation date")
      setSorter({ sortBy: "creationDate", sortOrder });

    if (sorter.column?.title === "Publication date")
      setSorter({ sortBy: "publicationDate", sortOrder });
  };

  useEffect(() => {
    if (localStorage.getItem(LocalStorageKeys.VIDEO_FORMAT_FILTER)) {
      setVideoFormat(
        ["article", "story"].includes(
          `${localStorage.getItem(LocalStorageKeys.VIDEO_FORMAT_FILTER)}`
        )
          ? (localStorage.getItem(LocalStorageKeys.VIDEO_FORMAT_FILTER) as
              | "story"
              | "article")
          : undefined
      );
    }

    const query = new URLSearchParams(location.search);
    setMetricsMode(JSON.parse(query.get("metrics") || "null"));

    return () => {
      if (!window.location.pathname.includes("/video"))
        localStorage.removeItem(LocalStorageKeys.VIDEO_FORMAT_FILTER);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filters?.search) handleSearch(filters.search);
    else fetchVideos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorter, filters, videoFormat]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (filters?.search) handleSearch(filters.search);
    }, 500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.search]);

  const columns: ColumnsType<IVideo> = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 480,
      render: (_text: string, record: IVideo) => (
        <Link to={`/content-management/video/${record.slug}`}>
          {record.title}
        </Link>
      ),
    },
    {
      title: "Status",
      key: "title",
      width: 160,
      render: (_text, record) => (
        <Tag
          color={
            record?.meta
              ? record?.meta?.status === "draft"
                ? "warning"
                : "success"
              : (record as IVideo & { status: string })?.status === "draft"
              ? "warning"
              : "success"
          }
        >
          {record?.meta
            ? record?.meta?.status?.toLocaleUpperCase()
            : (
                record as IVideo & { status: string }
              )?.status?.toLocaleUpperCase()}
        </Tag>
      ),
    },
    {
      title: "Access",
      dataIndex: "isPublic",
      key: "isPublic",
      width: 80,
      render: (isPublic: boolean) =>
        isPublic ? (
          <Tag color='green'>{"✅ Public"}</Tag>
        ) : (
          <Tag color='purple'>{"🔐 Private"}</Tag>
        ),
    },
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
      width: 150,
      render: (owner: IOrganisation) => {
        if (!owner) return <span>{"N/A"}</span>;

        return (
          <Link to={`/sensitive-data/organisations/${owner?.uid}`}>
            {owner.name}
          </Link>
        );
      },
    },
    {
      title: "Creation date",
      dataIndex: "title",
      key: "title",
      width: 160,
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_text, record: IVideo) => {
        return new Date(record?.meta?.creationDate)?.toLocaleDateString();
      },
    },
    {
      title: "Publication date",
      dataIndex: "title",
      key: "title",
      width: 160,
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_text: string, record: IVideo) => {
        return new Date(record?.publication_date)?.toLocaleDateString();
      },
    },
    {
      title: "Video format",
      dataIndex: "videoFormat",
      key: "videoFormat",
      width: 160,
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      width: 160,
      render: (company: ICompany) => {
        return company?.name;
      },
    },
    {
      title: "Medical Specialties",
      dataIndex: "medical_specialties",
      key: "medical_specialties",
      width: 480,
      render: (medical_specialties: ITag[]) =>
        medical_specialties
          ?.map((medicalSpecialty: ITag) =>
            medicalSpecialty.translations
              ? medicalSpecialty.translations[filters?.languages?.[0] || "en"]
              : (medicalSpecialty as ITag & { en: string }).en
          )
          .join(", "),
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      width: 480,
      render: (tags: ITag[]) =>
        tags
          ?.map((tag: ITag) =>
            tag.translations
              ? tag.translations[filters?.languages?.[0] || "en"]
              : (
                  filters?.medicalSpecialties as unknown as ITag & {
                    en: string;
                  }
                ).en
          )
          .join(", "),
    },
    {
      title: "Journal",
      dataIndex: "journal",
      key: "journal",
      width: 240,
      render: (journal: IJournal) => {
        return journal?.name;
      },
    },
  ];

  const columnsMetrics: ColumnsType<IVideo> = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 480,
      render: (_text: string, record: IVideo) => (
        <Link to={`/content-management/video/${record.slug}`}>
          {record.title}
        </Link>
      ),
    },
    {
      title: "Creation date",
      dataIndex: "title",
      key: "title",
      width: 160,
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_text, record: IVideo) => {
        return new Date(record?.meta?.creationDate)?.toLocaleDateString();
      },
    },
    {
      title: "Publication date",
      dataIndex: "title",
      key: "title",
      width: 160,
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_text: string, record: IVideo) => {
        return new Date(record?.publication_date)?.toLocaleDateString();
      },
    },
    {
      title: "Total Views",
      dataIndex: "metrics",
      key: "metrics",
      width: 80,
      render: (metrics: Record<string, number>) => metrics?.views,
    },
    {
      title: "Saves",
      dataIndex: "metrics",
      key: "metrics",
      width: 80,
      render: (metrics: Record<string, number>) => metrics?.saveds,
    },
    {
      title: "Likes",
      dataIndex: "metrics",
      key: "metrics",
      width: 80,
      render: (metrics: Record<string, number>) => metrics?.likes,
    },
    {
      title: "Shares",
      dataIndex: "metrics",
      key: "metrics",
      width: 80,
      render: (metrics: Record<string, number>) => metrics?.shares,
    },
  ];

  return (
    <Layout
      style={{
        overflow: "hidden",
        boxSizing: "border-box",
        height: "100vh",
      }}
    >
      <Layout.Content
        style={{
          padding: "20px 50px 50px",
          boxSizing: "border-box",
        }}
      >
        <Row
          justify='space-between'
          align='middle'
          style={{ margin: "20px 0" }}
        >
          <Col>
            <h1 style={{ fontWeight: 800, fontSize: "30px" }}>Videos</h1>
          </Col>
          <Col>
            <Button
              icon={<VideoCameraAddOutlined />}
              style={{ marginRight: 10 }}
              onClick={() => navigate("/content-management/video/requests")}
              type={"default"}
            >
              Requests
            </Button>
            <Button
              icon={<UnorderedListOutlined />}
              style={{ marginRight: 10 }}
              onClick={() => setMetricsMode(false)}
              type={!metricsMode ? "primary" : "default"}
            >
              Management
            </Button>
            <Button
              icon={<BarChartOutlined />}
              style={{ marginRight: 10 }}
              onClick={() => setMetricsMode(true)}
              type={metricsMode ? "primary" : "default"}
            >
              Metrics
            </Button>

            <Link to={"/content-management/video/create/"}>
              <Button type='primary' style={{ marginRight: 10 }}>
                New video
              </Button>
            </Link>
          </Col>
        </Row>
        <div className='site-layout-content'>
          <FiltersDrawer
            onChange={handleChangeFilters}
            fields={[
              "languages",
              "search",
              "owners",
              "rooms",
              "isPublic",
              "status",
              "medicalSpecialties",
              "tags",
            ]}
          >
            <Form.Item name='companies'>
              <Select
                allowClear
                mode='multiple'
                placeholder='Company'
                style={{ width: "100%" }}
                filterOption={searchFilterOptions}
                options={companyList.map((company) => ({
                  label: company.name,
                  value: company._id,
                }))}
              />
            </Form.Item>
            <Form.Item name='videoFormat'>
              <Select
                allowClear
                placeholder='Video Format'
                style={{ width: "100%" }}
                value={videoFormat}
              >
                <Select.Option key={"article"} value={"article"}>
                  Article
                </Select.Option>
                <Select.Option key={"story"} value={"story"}>
                  Story
                </Select.Option>
              </Select>
            </Form.Item>
          </FiltersDrawer>

          <div style={{ height: 16 }} />
          <Spin spinning={isLoading} tip='Loading...'>
            <Table
              sticky
              size='small'
              columns={metricsMode ? columnsMetrics : columns}
              dataSource={filters?.search?.length ? searchList : innerVideoList}
              pagination={false}
              scroll={{
                x: "max-video",
                y: "calc(100vh - 350px)",
              }}
              onChange={handleTableChange}
            />
          </Spin>
          <Pagination
            style={{ marginTop: "10px" }}
            onChange={handlePaginationChange}
            total={count}
            current={page}
            defaultCurrent={1}
            defaultPageSize={100}
            pageSizeOptions={["10", "20", "50", "100", "250", "500", "1000"]}
          />
        </div>
      </Layout.Content>
    </Layout>
  );
};
