import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Input, Table, Tabs } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GlobalContext } from "~/context/global.context";
import { useDispatch } from "react-redux";
import { fetchData } from "~/store/actions";
import { FETCH_TAGS } from "~/store/types";
import { AppstoreAddOutlined } from "@ant-design/icons";
import { Flex, Spacer } from "~/components/shared/global";
import { tagListColumns } from "./columns";
import Fuse from "fuse.js";

export const TagList: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { tagList } = useContext(GlobalContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState<string | undefined>(
    searchParams.get("search") || undefined
  );
  const [activeTab, setActiveTab] = useState<string>("tab-specialties");
  const [innerTagList, setInnerTagList] = useState(tagList);

  const handleClick = () => {
    navigate("/reference-data/tag/create/");
  };

  useEffect(() => {
    if (!tagList) dispatch(fetchData(FETCH_TAGS));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleSearch = () => {
      if (!searchValue) {
        setInnerTagList(tagList);
        return;
      }

      const fuse = new Fuse(tagList, {
        keys: ["translations.en", "parent.translations.en"],
        threshold: 0.1,
        includeScore: true,
      });

      const result = fuse.search(searchValue);

      const newTagList = result.map((r) => r.item);

      setInnerTagList(newTagList);
    };

    if (!searchValue || !searchValue.length) {
      searchParams.delete("search");
      setSearchParams(searchParams);
    } else setSearchParams({ search: searchValue });

    handleSearch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, tagList]);

  return (
    <div className='basic-layout'>
      <Flex justify='space-between' align='center'>
        <h1
          style={{ fontWeight: 800, fontSize: "30px" }}
          onClick={() =>
            console.log(tagList.filter((t) => t.uid.includes("anxiety")))
          }
        >
          {"Medical specialties & Tags"}
        </h1>
        <Button
          type='primary'
          onClick={handleClick}
          icon={<AppstoreAddOutlined />}
        >
          {"Create new Tag or Specialty"}
        </Button>
      </Flex>

      <Spacer />

      <Card>
        <Tabs
          centered
          items={[
            { label: "Medical specialties", key: "tab-specialties" },
            { label: "Tags", key: "tab-tags" },
          ]}
          onChange={(key) => setActiveTab(key)}
        />
        <Input.Search
          placeholder='Search tag'
          allowClear
          onSearch={setSearchValue}
          defaultValue={searchValue}
        />
        <Spacer />
        <Table
          loading={!innerTagList.length}
          columns={tagListColumns}
          dataSource={innerTagList.filter((v) =>
            activeTab === "tab-tags" ? v.parent : !v.parent
          )}
          scroll={{
            x: "max-infographic",
            y: "calc(100vh - 300px)",
          }}
          sticky
        />
      </Card>
    </div>
  );
};
