import { CloseOutlined, StarOutlined } from "@ant-design/icons";
import { Button, notification, Tag, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { GlobalContext } from "~/context/global.context";
import { SupportedLanguage } from "~/model/enum";
import { INectar } from "~/model/nectar.model";
import { addNectarHightlights, deleteNectarHightlights } from "~/services";
import { fetchData } from "~/store/actions";
import { FETCH_HIGHLIGHT_NECTARS } from "~/store/types";
import { getRequestErrorMessage, languageLabel } from "~/utils/helpers";

export const useNectarColumns = () => {
  const dispatch = useDispatch();
  const { highlightNectars: highlightList } = useContext(GlobalContext);

  const removeHighlight = async (nectarId: string) => {
    try {
      await deleteNectarHightlights(nectarId);

      dispatch(fetchData(FETCH_HIGHLIGHT_NECTARS));

      notification.success({
        message: "Highlight removed successfully!",
        description: "You will no longer see this nectar in the highlights",
      });
    } catch (error) {
      notification.error({
        message: "Failed to remove highlight",
        description: getRequestErrorMessage(error),
      });
    }
  };

  const addHighlight = async (nectar: INectar) => {
    const nectarId = nectar._id;
    const isHighlighted = highlightList?.find((el) =>
      el.highlight.find((el) => el.nectarId === nectarId)
    );

    if (isHighlighted) return removeHighlight(nectarId);

    try {
      notification.info({
        key: "loading-notification",
        message: "New highlight is coming...",
        description:
          "This nectar will be highlighted soon, please wait for a moment",
      });
      await addNectarHightlights(nectarId);

      dispatch(fetchData(FETCH_HIGHLIGHT_NECTARS));

      notification.close("loading-notification");

      notification.success({
        message: "Nectar highlighted successfully!",
        description: "You will see this nectar in the highlights",
      });
    } catch (error) {
      notification.error({
        message: "Failed to highlight nectar",
        description: getRequestErrorMessage(error),
      });
    }
  };

  const columns: ColumnsType<INectar> = [
    {
      title: "Status",
      dataIndex: ["meta", "status"],
      key: "status",
      width: 60,
      render: (status) => (
        <Tag
          color={status === "published" ? "geekblue" : "orange"}
          style={{ marginLeft: 12 }}
        >
          {status?.toLocaleUpperCase()}
        </Tag>
      ),
    },
    {
      title: "Title",
      dataIndex: "question",
      key: "question",
      width: 250,
      render: (title, nectar) => (
        <Link to={`/content-management/nectar/${nectar._id}`}>{title}</Link>
      ),
    },
    {
      title: "Access",
      dataIndex: "isPublic",
      key: "isPublic",
      width: 80,
      render: (isPublic: boolean) =>
        isPublic ? (
          <Tag color='green'>{"✅ Public"}</Tag>
        ) : (
          <Tag color='purple'>{"🔐 Private"}</Tag>
        ),
    },
    {
      title: "Owner",
      dataIndex: ["owner"],
      key: "owner",
      width: 150,
      render: (owner) => owner?.name || "N/A",
    },
    {
      title: "Language",
      dataIndex: "language",
      key: "language",
      width: 100,
      render: (language: SupportedLanguage) => languageLabel[language],
    },
    {
      title: "Answer",
      dataIndex: "answer",
      key: "answer",
      width: 400,
      render: (answer: string) => (
        <div
          style={{
            display: "-webkit-box",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {answer}
        </div>
      ),
    },
    {
      title: "Creation date",
      dataIndex: ["meta", "creationDate"],
      key: "creationDate",
      width: 150,
      render: (creationDate: string) =>
        moment(creationDate).format("MM/DD/YYYY"),
    },
    {
      title: "Sources",
      dataIndex: ["associatedArticles"],
      key: "sources",
      width: 100,
      render: (associatedArticles) => `${associatedArticles?.length} articles`,
    },

    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 30,
      align: "center",
      render: (_, nectar) => {
        const isHighlighted = !!highlightList?.some((el) =>
          el.highlight.find((el) => el.nectarId === nectar._id)
        );

        return (
          <Tooltip
            title={
              isHighlighted ? "Remove from highlights" : "Highlight this nectar"
            }
          >
            <Button
              type={isHighlighted ? "primary" : "default"}
              icon={isHighlighted ? <CloseOutlined /> : <StarOutlined />}
              onClick={() => addHighlight(nectar)}
            />
          </Tooltip>
        );
      },
    },
  ];

  return { columns };
};
