
import { Select, SelectProps } from "antd";
import { useProfessions } from "~/hooks/use-professions";
import { SupportedLanguage } from "~/model/enum";

export const ProfessionSelect = (selectProps: SelectProps) => {
  const { professions, isLoading } = useProfessions(true);
  return <Select
    placeholder="Select profession"
    options={(professions ?? []).map((profession) => ({
      label: profession.translations[SupportedLanguage.EN],
      value: profession.uid,
    })) ?? []}
    loading={isLoading}
    {...selectProps}
  />;
};
