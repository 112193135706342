import {
  CloudDownloadOutlined,
  CloudUploadOutlined,
  ExclamationCircleOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Button, Divider, FormInstance, Modal, notification, Tag } from "antd";
import { Dispatch, SetStateAction, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Flex, Spacer } from "~/components/shared/global";
import { GlobalContext } from "~/context/global.context";
import { IArticle } from "~/model/article.model";
import {
  ContentFormatsEnum,
  PermissionEnum,
  PermissionLabel,
} from "~/model/enum";
import { IInfographic } from "~/model/infographic.model";
import { INectar } from "~/model/nectar.model";
import { IPlaylist } from "~/model/playlist.model";
import { IVideo } from "~/model/video.model";
import {
  deleteArticle,
  deleteInfographic,
  deleteNectar,
  deletePlaylist,
  deleteVideo,
  publishArticle,
  publishInfographic,
  publishNectar,
  publishPlaylist,
  publishVideo,
  unpublishArticle,
  unpublishInfographic,
  unpublishNectar,
  unpublishPlaylist,
  unpublishVideo,
} from "~/services";
import { checkPermission, getRequestErrorMessage } from "~/utils/helpers";
import BackButton from "./shared/BackButton";
import { getPublicContentUrl } from "~/utils/content";
import { IContent } from "~/model/content.model";

type InnerContentType = IPlaylist | IVideo | IInfographic | IArticle | INectar;

export type PageHeaderSetContent = Dispatch<
  SetStateAction<InnerContentType | undefined>
>;

type EditionHeaderProps = {
  format: ContentFormatsEnum;
  form: FormInstance;
  children?: React.ReactNode;
  content?: InnerContentType;
  setContent: PageHeaderSetContent;
};

export default function EditionPageHeader({
  form,
  format,
  children,
  content,
  setContent,
}: EditionHeaderProps) {
  const navigate = useNavigate();
  const { id: contentId } = useParams();
  const { user } = useContext(GlobalContext);

  const isPublished = content?.meta?.status === "published";

  const canAdminDelete =
    !!user &&
    checkPermission(user, PermissionLabel.Content, PermissionEnum.Delete);

  async function publishContent(contentId: string) {
    switch (format) {
      case ContentFormatsEnum.VIDEO:
        await publishVideo(contentId);
        break;

      case ContentFormatsEnum.ARTICLE:
        await publishArticle(contentId);
        break;

      case ContentFormatsEnum.INFOGRAPHIC:
        await publishInfographic(contentId);
        break;

      case ContentFormatsEnum.PLAYLIST:
        await publishPlaylist(contentId);
        break;

      case ContentFormatsEnum.NECTAR:
        await publishNectar(contentId);
        break;

      default:
        break;
    }
  }

  async function unpublishContent(contentId: string) {
    switch (format) {
      case ContentFormatsEnum.VIDEO:
        await unpublishVideo(contentId);
        break;

      case ContentFormatsEnum.ARTICLE:
        await unpublishArticle(contentId);
        break;

      case ContentFormatsEnum.INFOGRAPHIC:
        await unpublishInfographic(contentId);
        break;

      case ContentFormatsEnum.PLAYLIST:
        await unpublishPlaylist(contentId);
        break;

      case ContentFormatsEnum.NECTAR:
        await unpublishNectar(contentId);
        break;

      default:
        break;
    }
  }

  async function handlePublish() {
    if (content) {
      try {
        content?.meta?.status === "draft"
          ? await publishContent(content._id)
          : await unpublishContent(content._id);

        setContent({
          ...content,
          meta: {
            ...content.meta,
            status: content?.meta?.status === "draft" ? "published" : "draft",
          },
        });

        notification.success({
          message: `${format} updated`,
          description: `The ${format} was succesfully updated.`,
          placement: "bottomRight",
        });
      } catch (error) {
        notification.error({
          message: "Error",
          description: getRequestErrorMessage(error),
          placement: "bottomRight",
        });
      }
    }
  }

  async function handleDelete() {
    if (content)
      Modal.confirm({
        icon: <ExclamationCircleOutlined />,
        content: <p>{"Are you sure to delete this content?"}</p>,
        async onOk() {
          switch (format) {
            case ContentFormatsEnum.VIDEO:
              await deleteVideo(content._id);
              break;

            case ContentFormatsEnum.ARTICLE:
              await deleteArticle((content as IArticle).slug);
              break;

            case ContentFormatsEnum.INFOGRAPHIC:
              await deleteInfographic(content._id);
              break;

            case ContentFormatsEnum.PLAYLIST:
              await deletePlaylist(content._id);
              break;

            case ContentFormatsEnum.NECTAR:
              await deleteNectar(content._id);
              break;

            default:
              break;
          }

          navigate(-1);

          notification.success({
            message: `${format} deleted`,
            description: `The ${format} was succesfully deleted.`,
          });
        },
      });
  }

  return (
    <div>
      <BackButton />

      <Flex justify='space-between' align='start' gap={16}>
        <div>
          <h1 style={{ fontWeight: 800, fontSize: "30px" }}>
            {contentId ? `Edit ${format}` : `Create ${format}`}
          </h1>
        </div>

        <div>
          <Flex
            gap={4}
            align='center'
            justify='end'
            style={{ flexWrap: "wrap" }}
          >
            {children}
            {!!children && <Divider type='vertical' />}

            {contentId ? (
              <Tag
                style={{
                  padding: "5px 7px",
                  fontSize: "14px",
                  margin: 0,
                  textAlign: "center",
                  width: 120,
                }}
                color={!isPublished ? "warning" : "success"}
              >
                {content?.meta?.status?.toUpperCase()}
                {isPublished && (
                  <a
                    href={getPublicContentUrl(content as IContent)}
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ marginLeft: "2px" }}
                  >
                    <LogoutOutlined rotate={-45} style={{ color: "green" }} />
                  </a>
                )}
              </Tag>
            ) : null}

            {contentId && <Divider type='vertical' />}

            {contentId && (
              <Button
                style={{ width: 120 }}
                icon={
                  isPublished ? (
                    <CloudDownloadOutlined />
                  ) : (
                    <CloudUploadOutlined />
                  )
                }
                onClick={handlePublish}
              >
                {!isPublished ? "Publish " : "Unpublish"}
              </Button>
            )}
            <Divider type='vertical' />
            <Button type='primary' onClick={() => form.submit()}>
              {contentId ? `Save ${format}` : `Create ${format}`}
            </Button>
            {contentId ? (
              <Button
                danger
                style={{
                  marginLeft: "20px",
                }}
                onClick={handleDelete}
                disabled={!canAdminDelete}
              >
                {`Delete ${format}`}
              </Button>
            ) : null}
          </Flex>
        </div>
      </Flex>

      <h3 style={{ marginTop: 8 }}>{content?.title}</h3>

      <Spacer />
    </div>
  );
}
