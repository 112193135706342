import { useContext } from "react";
import { ParsingContext } from "~/context/parsing.context";

export const useParsingContext = () => {
  const context = useContext(ParsingContext);

  if (!context) {
    throw new Error("useParsingContext must be used within a ParsingProvider");
  }

  return context;
};
