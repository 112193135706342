import { ContentFormatsEnum } from "~/model/enum";
import { IPlaylist } from "~/model/playlist.model";

export type ContentTabPaneModel = {
  title: string;
  type: ContentFormatsEnum;
  contentList: IPlaylist["playlistContent"] | IPlaylist["playlist"] | undefined;
};

export const getContentTabPanes = (
  innerPlaylist?: IPlaylist
): ContentTabPaneModel[] => {
  return [
    {
      title: "Article",
      type: ContentFormatsEnum.ARTICLE,
      contentList: innerPlaylist?.playlist,
    },
    {
      title: "Video",
      type: ContentFormatsEnum.VIDEO,
      contentList: innerPlaylist?.playlistContent?.filter(
        (el) => el.content_format === ContentFormatsEnum.VIDEO
      ),
    },
    {
      title: "Nectar",
      type: ContentFormatsEnum.NECTAR,
      contentList: innerPlaylist?.playlistContent?.filter(
        (el) => el.content_format === ContentFormatsEnum.NECTAR
      ),
    },
  ];
};
