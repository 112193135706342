import { TabsProps } from "antd";
import NectarAskLlm from "./ask-llm/NectarAskLlm";
import {
  FireOutlined,
  HistoryOutlined,
  QuestionCircleOutlined,
  StarOutlined,
} from "@ant-design/icons";
import NectarHistory from "./question/NectarQuestionList";
import NectarList from "./list/NectarList";

export const ITEMS = [
  {
    key: "ask-llm",
    icon: QuestionCircleOutlined,
    label: "Ask JuisciGPT",
    children: NectarAskLlm,
  },
  {
    key: "questions",
    icon: HistoryOutlined,
    label: "History",
    children: NectarHistory,
  },
  {
    key: "nectars",
    icon: FireOutlined,
    label: "Nectars",
    children: NectarList,
  },
  {
    key: "highlights",
    icon: StarOutlined,
    label: "Highlighted Nectars",
    children: NectarList,
  },
];

export const nectarTabItems: TabsProps["items"] = ITEMS.map(
  ({ key, label, icon: Icon, children: Children }) => ({
    key,
    label: (
      <h3>
        <Icon /> {label}
      </h3>
    ),
    children: <Children />,
    style: { padding: "24px 48px" },
  })
);
