import { useEffect, useRef, useState } from "react";
import { IProfession } from "~/model/profession.model";
import { getProfessionList } from "~/services";

export const useProfessions = (fetchAll: boolean = false, pageSize: number = 20) => {
  const [professions, setProfessions] = useState<IProfession[] | null>(null);
  const lastMeta = useRef<{
    total: number;
    offset: number;
    limit: number;
  } | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchNextPage = async () => {
    const offset = !lastMeta.current ? 0 : lastMeta.current.offset + lastMeta.current.limit;
    const { docs, meta: newMeta } = await getProfessionList({
      offset,
      limit: pageSize,
    });
    lastMeta.current = newMeta;
    setProfessions((prevProfessions) => [
      ...(prevProfessions ?? []),
      ...docs,
    ]);
    if (newMeta?.total <= newMeta.limit + newMeta.offset) {
      return;
    }
    if (fetchAll) {
      await fetchNextPage();
    }
  };

  useEffect(() => {
    const fetchProfessions = async () => {
      setIsLoading(true);
      try {
        await fetchNextPage();
      } catch (err) {
        setError(err as Error);
        setProfessions(null);
        lastMeta.current = null;
      } finally {
        setIsLoading(false);
      }
    };

    fetchProfessions();
  }, []);

  return { professions, isLoading, error, fetchNextPage };
};
