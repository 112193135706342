import { LinkOutlined, RocketOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, notification, Space } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { parseArticleFromDoi } from "~/services";
import { getRequestErrorMessage } from "~/utils/helpers";

export const CreateParsingDoiForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  async function handleSubmit(values: { doiList: string; batchName?: string }) {
    setIsSubmitting(true);

    try {
      const doiRegexUrl = /^https:\/\/doi\.org\/10\.\d{4,9}\/[\w\-.]+$/;

      const batchName = values.batchName || undefined;

      const doiList = values.doiList
        .split("\n")
        .filter((doi) => doiRegexUrl.test(doi) && doi.trim());

      if (!doiList.length) {
        notification.error({
          message: "No valid DOI found.",
          description: "Please provide at least one valid DOI.",
        });
        return;
      }

      if (doiList.length < values.doiList.split("\n").length) {
        notification.warning({
          message: "Some DOI could not be parsed.",
          description: "Please check the format of the DOIs.",
        });
        return;
      }

      await parseArticleFromDoi({ doiList, batchName });

      notification.success({
        message: "🚀 Parsing tasks sent to the Moon!",
        description:
          "Please wait for a moment to get the result, it will be shown in the table below.",
      });
    } catch (error) {
      notification.error({
        message: "An error has occured while parsing.",
        description: getRequestErrorMessage(error),
      });
    } finally {
      setTimeout(() => {
        navigate(-1);
        setIsSubmitting(false);
      }, 1000); // Wait for API to update db values
    }
  }
  return (
    <Card
      title={
        <h3>
          <Space>
            <LinkOutlined />
            {"Parse from DOI links"}
          </Space>
        </h3>
      }
    >
      <Form form={form} onFinish={handleSubmit} layout='horizontal'>
        <Form.Item name='batchName'>
          <Input placeholder='Batch name' />
        </Form.Item>
        <Form.Item name='doiList' required>
          <Input.TextArea
            autoSize={{ minRows: 10, maxRows: 30 }}
            placeholder='https://doi.example.10.1109/5.771073&#10;https://doi.example.10.1206/2.438&#10;...'
            allowClear={true}
          />
        </Form.Item>
        <Form.Item>
          <Button
            block
            htmlType='submit'
            type='primary'
            icon={<RocketOutlined />}
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            {isSubmitting ? "Sending tasks..." : "Parse now!"}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};
