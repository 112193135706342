import { useEffect, useMemo, useRef, useState } from "react";
import QRCodeStyling, {
  FileExtension
} from "qr-code-styling";
import { qrConfigs, Theme } from "./config";
export * from './config';

export const QRCodeEditor = ({ qrCode }: { qrCode: QRCodeStyling }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.innerHTML = '';
      qrCode.append(ref.current);
    }
  }, [qrCode, ref]);

  return <div ref={ref} />;
};

export function useQRCodeEditor(defaultUrl: string = '') {
  const [url, setUrl] = useState<string>(defaultUrl);
  const [size, setSize] = useState<number>(300);
  const [theme, setTheme] = useState<Theme>('default');
  const options = useMemo(() => ({
    ...qrConfigs[theme],
    data: url,
    width: size,
    height: size,
  }), [theme, url, size]);
  const imageUrlRef = useRef<string | null>(options.image || null);
  const [qrCode, setQrCode] = useState<QRCodeStyling>(new QRCodeStyling(options));

  useEffect(() => {
    if (options.image != imageUrlRef.current) {
      // qrCode.update() doesn't work when the logo image changes
      setQrCode(new QRCodeStyling(options));
      imageUrlRef.current = options.image || null;
      return;
    }
    qrCode.update(options);
  }, [qrCode, options]);

  const getData = async () => {
    const data = await qrCode.getRawData(options.type as FileExtension);
    return data;
  };

  return {
    qrCode,
    QRCodeEditor: () => <QRCodeEditor qrCode={qrCode} />,
    getData,
    size,
    theme,
    url,
    setSize,
    setTheme,
    setUrl,
  };
}