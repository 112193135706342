import { Tabs } from "antd";
import { LocalStorageKeys } from "~/utils/constants";
import { nectarTabItems } from "./tabs-items";
import { NectarActiveTab, NectarProvider } from "~/context/nectar.context";
import { useNectarContext } from "~/hooks/nectars/use-nectar-context";

const NectarRouterWithoutContext = () => {
  const { activeTab, setActiveTab } = useNectarContext();

  const handleChangeTabPane = (key: string) => {
    setActiveTab(key as NectarActiveTab);
    localStorage.setItem(LocalStorageKeys.NECTAR_ACTIVE_TAB, key);
  };

  return (
    <div className='basic-layout' style={{ padding: 0 }}>
      <Tabs
        centered
        activeKey={String(activeTab)}
        onChange={handleChangeTabPane}
        tabBarStyle={{
          background: "#fff",
          paddingTop: 8,
        }}
        items={nectarTabItems}
      />
    </div>
  );
};

export default function NectarRouter() {
  return (
    <NectarProvider>
      <NectarRouterWithoutContext />
    </NectarProvider>
  );
}
