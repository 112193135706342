import { IContent } from "~/model/content.model";
import { ContentFormatsEnum } from "~/model/enum";

const env = import.meta.env.VITE_APP_ENV;

export const getPublicContentPath = (content: IContent) => {
	if ('article_type' in content) {
		return `/post/${content.slug}`;
	}
	if (content.content_format === ContentFormatsEnum.INFOGRAPHIC) {
		return `/infographic/${content.slug}`;
	}
	if (content.content_format === ContentFormatsEnum.VIDEO) {
		return `/video/story/${content.slug}`;
	}
	if (content.content_format === ContentFormatsEnum.NECTAR) {
		return `/nectar/${content.slug}`;
	}
	if (content.content_format === ContentFormatsEnum.PLAYLIST) {
		return `/playlist/public/${content.slug}`;
	}
};

export const getPublicContentUrl = (content: IContent) => {
	const appUrl = env === "production" ? "https://app.juisci.com" : "https://dev.juisci.com";
	return `${appUrl}${getPublicContentPath(content)}`;
};
