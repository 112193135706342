import { Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";
import { SupportedLanguage } from "~/model/enum";
import { ITag } from "~/model/tag.model";
import { languageLabel } from "~/utils/helpers";

export const tagListColumns: ColumnsType<ITag> = [
  {
    title: "Name",
    key: "name",
    render: (_, tag) => (
      <Link to={`/reference-data/tag/${tag.uid}`}>{tag.translations.en}</Link>
    ),
  },
  {
    title: "Parent",
    key: "parent",
    render: (_, tag) =>
      tag.parent ? (
        <Link to={`/reference-data/tag/${tag.parent.uid}`}>
          {tag.parent.translations.en}
        </Link>
      ) : (
        "N/A"
      ),
  },
  {
    title: "Missing translations",
    key: "missing-translations",
    render: (_, tag) => (
      <>
        {Object.values(SupportedLanguage)
          .filter((lang) => !tag.translations[lang])
          .map((key) => (
            <Tag key={key} color='red'>
              {languageLabel[key]}
            </Tag>
          ))}
      </>
    ),
  },
];
