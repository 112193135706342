import {
  Button,
  Divider,
  Form,
  Input,
  message,
  notification,
  Select,
} from "antd";
import { useContext } from "react";
import { DebounceSelect } from "~/components/shared/DebounceSelect";
import { Flex } from "~/components/shared/global";
import { GlobalContext } from "~/context/global.context";
import { useNectarContext } from "~/hooks/nectars/use-nectar-context";
import { useQuestionsQuery } from "~/hooks/questions/use-questions-query";
import { useAskLlmMutation } from "~/hooks/use-ask-llm-mutation";
import { IArticle } from "~/model/article.model";
import { AskLlmFormDto } from "~/model/dto/llm-dto";
import { searchArticlesV2 } from "~/services";
import {
  getRequestErrorMessage,
  languageOptions,
  searchFilterOptions,
} from "~/utils/helpers";

export default function NectarAskLlm() {
  const [form] = Form.useForm<AskLlmFormDto>();
  const { organisationList, tagList } = useContext(GlobalContext);
  const { mutate, error, loading: loadingAskQuestion } = useAskLlmMutation();
  const { fetch: fetchQuestions } = useQuestionsQuery();
  const { setActiveTab } = useNectarContext();

  const handleSubmitQuestion = async () => {
    if (form.getFieldValue("question") === "")
      return message.error({ content: "Please enter a question" });
    if (form.getFieldValue("language") === undefined)
      return message.error({ content: "Please select a language" });
    if (form.getFieldValue("owner") === undefined)
      return message.error({ content: "Please select an organisation" });

    const values = form.getFieldsValue();

    const articles = (values.articles || []).map((v) => v.value);

    const nectarRequest = await mutate({ ...values, articles });

    if (error || !nectarRequest) return;

    message.success({
      content: "Question submitted successfully!",
    });

    fetchQuestions({ limit: 10, offset: 0 });
    setActiveTab("questions");
    form.resetFields();
  };

  const handleSearchArticles = async (search: string) => {
    const languages = form.getFieldValue("language")
      ? [form.getFieldValue("language")]
      : undefined;

    // TODO: Use _id instead of uid once API fixed
    const medical_specialties = form.getFieldValue("medicalSpecialties")
      ? tagList
          .filter((spe) =>
            form.getFieldValue("medicalSpecialties").includes(spe._id)
          )
          .map((spe) => spe.uid)
      : undefined;

    try {
      const res = await searchArticlesV2({
        search,
        languages,
        medical_specialties,
      });

      if (!res.hits.hits) throw new Error("No articles found");

      const formatted = res.hits.hits.map((article) => ({
        ...article._source.core,
        ...article._source.filters,
        owner: organisationList.find(
          (o) => o._id === (article._source.filters.owner as unknown as string)
        ),
        _id: article._source.id,
      })) as IArticle[];

      return formatted.map((article) => ({
        label: article.title,
        value: article._id,
      }));
    } catch (error) {
      notification.error({
        message: "Failed to search articles",
        description: getRequestErrorMessage(error),
      });

      return [];
    }
  };

  return (
    <div>
      <h1 style={{ fontWeight: 800, fontSize: "30px" }}>Ask JuisciGPT</h1>
      <Divider />
      <Form
        form={form}
        initialValues={{
          owner: organisationList.find((org) => org.uid === "juisci")?._id,
        }}
        onFinish={handleSubmitQuestion}
      >
        <Flex gap={8} align='center'>
          <Form.Item name='question' style={{ width: "100%" }}>
            <Input size='large' placeholder='Ask a question to the LLM' />
          </Form.Item>
          <Form.Item name='language'>
            <Select
              size='large'
              placeholder='Language'
              options={languageOptions}
            />
          </Form.Item>
          <Form.Item name='owner'>
            <Select
              style={{ width: 200 }}
              placeholder='Owner'
              showSearch
              size='large'
              defaultValue={
                organisationList.find((org) => org.uid === "juisci")?._id
              }
              options={organisationList.map((organisation) => ({
                label: organisation.name,
                value: organisation._id,
              }))}
              filterOption={searchFilterOptions}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              size='large'
              htmlType='submit'
              disabled={loadingAskQuestion}
              loading={loadingAskQuestion}
            >
              {"Submit question"}
            </Button>
          </Form.Item>
        </Flex>
        <Form.Item
          name='medicalSpecialties'
          label='Filter by medical Specialties'
        >
          <Select
            mode='multiple'
            placeholder='Specify Medical Specialties'
            options={tagList
              .filter((tag) => !tag.parent)
              .map((spe) => ({
                label: spe.translations.en,
                value: spe._id,
              }))}
            filterOption={searchFilterOptions}
          />
        </Form.Item>
        <Form.Item name='articles' label='Filter on specific articles'>
          <DebounceSelect
            style={{ width: "100%", marginBottom: 16 }}
            mode='multiple'
            placeholder='Search articles'
            fetchOptions={handleSearchArticles}
          />
        </Form.Item>
      </Form>
    </div>
  );
}
