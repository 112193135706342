import {
  ArrowLeftOutlined,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Button, Divider, Modal, notification, Select, Space } from "antd";
import { Flex } from "../shared/global";
import {
  useParams,
  useNavigate,
  useSearchParams,
  Link,
} from "react-router-dom";
import {
  deleteParsing,
  deleteParsingBatch,
  postRetryAllParsing,
  postRetryParsing,
} from "~/services";
import { getRequestErrorMessage } from "~/utils/helpers";
import { IParsing } from "~/model/parser.model";
import { useParsingContext } from "~/hooks/parsings/use-parsing-context";
import { ParsingStatusEnum } from "~/model/enum";

export const ParsingListHeader = () => {
  const navigate = useNavigate();
  const params = useParams<{ batchName: string }>();
  const batchMode = !!params.batchName;
  const [searchParams, setSearchParams] = useSearchParams();
  const { list, rowSelection, setRowSelection, pagination, setPagination } =
    useParsingContext();

  const deleteSelectedParsings = async () => {
    Modal.confirm({
      title: "Are you sure you want to delete the selected parsings?",
      content: "This action cannot be undone.",
      onOk: async () => {
        await Promise.all(
          rowSelection.map(async (parsing) => {
            if (parsing.batchName && !params.batchName)
              await deleteParsingBatch(parsing.batchName);
            else await deleteParsing(parsing.id);
          })
        );
        notification.success({
          message: "Parsings deleted successfully",
        });

        setRowSelection([]);
        setSearchParams(searchParams); // to trigger fetch parsing list

        if (params.batchName) navigate(-1);
      },
    });
  };

  const handleRetryParsings = async () => {
    Modal.confirm({
      title: "Are you sure you want to retry the selected parsings?",
      content: "This action cannot be undone.",
      onOk: async () => {
        try {
          await Promise.all(
            rowSelection.map(async (parsing) => {
              await postRetryParsing(parsing.taskId);
            })
          );
          notification.success({
            message: "Parsings re-tried successfully",
          });

          setRowSelection([]);

          setSearchParams(searchParams); // to trigger fetch parsing list
        } catch (error) {
          notification.error({
            message: "An error occurred while retrying parsing",
            description: getRequestErrorMessage(error),
          });
        }
      },
    });
  };

  const handleRetryAllParsings = async () => {
    try {
      await postRetryAllParsing();
      notification.success({
        message: "All parsings re-tried successfully",
      });
    } catch (error) {
      notification.error({
        message: "An error occurred while retrying parsing",
        description: getRequestErrorMessage(error),
      });
    }
  };

  const handleChangeStatus = (status?: IParsing["meta"]["status"]) => {
    if (pagination) {
      setPagination({ ...pagination, current: 1 });
      searchParams.set("offset", "0");
    }

    if (rowSelection.length) setRowSelection([]);

    if (status) searchParams.set("status", status);
    else searchParams.delete("status");

    setSearchParams(searchParams);
  };

  const handleChangeSource = (source?: "doi" | "pdf") => {
    if (pagination) {
      setPagination({ ...pagination, current: 1 });
      searchParams.set("offset", "0");
    }

    if (rowSelection.length) setRowSelection([]);

    if (source) searchParams.set("source", source);
    else searchParams.delete("source");
    setSearchParams(searchParams);
  };

  const handleSortChange = (value?: "asc" | "desc") => {
    searchParams.set("sortOrder", value || "desc");
    searchParams.set("sortBy", "creationDate");
    setSearchParams(searchParams);
  };

  const hasFailure = list?.some(
    (el) => el.meta.status === ParsingStatusEnum.FAILURE
  );
  const selectedFailure = rowSelection.some(
    (el) => el.meta.status === ParsingStatusEnum.FAILURE
  );

  return (
    <Space direction='vertical' style={{ width: "100%" }}>
      {batchMode ? (
        <Button
          icon={<ArrowLeftOutlined />}
          type='link'
          style={{ padding: 0 }}
          onClick={() => navigate(-1)}
        >
          {"Back to parsings list"}
        </Button>
      ) : null}
      <Flex justify='space-between' align='start'>
        <h1>
          {batchMode ? `Batch parsing "${params.batchName}"` : "Parsing list"}
        </h1>

        <Space align='center'>
          {!rowSelection.length && hasFailure ? (
            <Button icon={<SyncOutlined />} onClick={handleRetryAllParsings}>
              {"Retry All Failed"}
            </Button>
          ) : selectedFailure ? (
            <Button icon={<SyncOutlined />} onClick={handleRetryParsings}>
              {"Retry selected"}
            </Button>
          ) : null}

          {rowSelection.length > 0 ? (
            <Button
              type='primary'
              onClick={deleteSelectedParsings}
              disabled={!rowSelection.length}
              danger
            >
              Delete selected
            </Button>
          ) : null}

          {!batchMode && (
            <Link to='create'>
              <Button type='primary' icon={<PlusOutlined />}>
                {"New parsing"}
              </Button>
            </Link>
          )}
        </Space>
      </Flex>

      <Space align='center' size='small'>
        <div style={{ color: "#000", fontWeight: 500 }}>
          {"Filter by status:"}
        </div>
        <Select
          placeholder='Status'
          style={{ width: 120 }}
          options={Object.entries(ParsingStatusEnum).map(([label, value]) => ({
            label,
            value,
          }))}
          onChange={handleChangeStatus}
          allowClear
        />
        <Divider type='vertical' />
        <div style={{ color: "#000", fontWeight: 500 }}>
          {"Sort by creation date"}
        </div>
        <Select
          placeholder='Asc / Desc'
          style={{ width: 120 }}
          defaultValue={"desc"}
          options={[
            {
              label: "Ascending",
              value: "asc",
            },
            {
              label: "Descending",
              value: "desc",
            },
          ]}
          onChange={handleSortChange}
        />
        <Divider type='vertical' />
        <div style={{ color: "#000", fontWeight: 500 }}>
          {"Sort by parsing source"}
        </div>
        <Select
          allowClear
          disabled={batchMode}
          placeholder='Source'
          style={{ width: 120 }}
          onChange={handleChangeSource}
          options={[
            {
              label: "DOI",
              value: "doi",
            },
            {
              label: "PDF",
              value: "pdf",
            },
          ]}
        />
      </Space>
    </Space>
  );
};
