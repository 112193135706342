import QRCodeStyling from "qr-code-styling";
import { useShortLinkMutation } from "../use-short-link-mutation";
import { qrConfigs, Theme } from "./config";

export const useQrCodeInviteCreator = () => {
  const { mutate: upsertShortLink } = useShortLinkMutation();

  const create = async ({
    name,
    path,
    qrCodeUrl,
    tags,
    qrCodeTheme = 'dotted-with-logo',
    size = 1000
  }: {
    name: string;
    path: string;
    qrCodeUrl: string;
    qrCodeTheme?: Theme;
    size?: number;
    tags?: string[];
  }) => {
    const container = document.createElement('div');
    container.style.display = 'none';
    document.body.appendChild(container);
    const qrCode = new QRCodeStyling({
      ...qrConfigs[qrCodeTheme],
      data: qrCodeUrl,
      width: size,
      height: size,
    });
    qrCode.append(container);
    const qrCodeImage = await qrCode.getRawData('png') as Blob | null;
    const res = await upsertShortLink({
      name: name || '',
      path,
      redirectTo: qrCodeUrl || '',
      qrCodeImage,
      tags,
    });
    container.parentNode?.removeChild(container);
    return res;
  }

  return create;
};
