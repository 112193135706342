import { useContext } from "react";
import { NectarContext } from "~/context/nectar.context";

export const useNectarContext = () => {
  const context = useContext(NectarContext);

  if (!context) {
    throw new Error("useNectarContext must be used within a NectarProvider");
  }

  return context;
};
