import React, { useEffect } from "react";
import { notification, Space, Spin, Table } from "antd";
import { getParsingList } from "~/services";
import { useParams, useSearchParams } from "react-router-dom";
import columns from "./columns";
import { IQueryParsing } from "~/model/query.model";
import { useParsingContext } from "~/hooks/parsings/use-parsing-context";
import { getRequestErrorMessage } from "~/utils/helpers";
import { ParsingProvider } from "~/context/parsing.context";
import { ParsingListHeader } from "~/components/parsing/ParsingListHeader";
import { IParsing } from "~/model/parser.model";

function ParsingList() {
  const params = useParams<{ batchName: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    list,
    setList,
    pagination,
    setPagination,
    rowSelection,
    setRowSelection,
    isFetching,
    setIsFetching,
  } = useParsingContext();

  const fetchParsingList = async () => {
    setIsFetching(true);
    try {
      const query = {
        limit: Number(searchParams.get("limit")) || 50,
        offset: Number(searchParams.get("offset")) || 0,
        status:
          (searchParams.get("status") as IQueryParsing["status"]) || undefined,
        sortOrder:
          (searchParams.get("sortOrder") as IQueryParsing["sortOrder"]) ||
          "desc",
        sortBy:
          (searchParams.get("sortBy") as IQueryParsing["sortBy"]) ||
          "creationDate",
        batchName: params.batchName || undefined,
        parsingType:
          (searchParams.get("source") as IQueryParsing["parsingType"]) ||
          undefined,
      };

      const { docs: parsingList, meta } = await getParsingList(query);

      setList(parsingList);

      setPagination({
        ...pagination,
        current: meta.offset / meta.limit + 1,
        total: meta.total,
        pageSize: meta.limit,
      });
    } catch (error) {
      notification.error({
        message: "An error has occured while fetching parsings.",
        description: getRequestErrorMessage(error),
      });
    } finally {
      setIsFetching(false);
    }
  };

  const handlePaginationChange = async (page: number, pageSize?: number) => {
    if (!pageSize || !page) return;

    setPagination({
      ...pagination,
      current: page,
      pageSize,
    });

    const offset = (page - 1) * pageSize < 0 ? 0 : (page - 1) * pageSize;
    const limit = pageSize;

    searchParams.set("offset", offset.toString());
    searchParams.set("limit", limit.toString());
    setSearchParams(searchParams);
  };

  function filterInnerParsing() {
    const seenBatchNames = new Set<string>();

    if (params.batchName) return () => true;

    return (parsing: IParsing) => {
      if (!parsing.batchName) return true;

      if (!seenBatchNames.has(parsing.batchName)) {
        seenBatchNames.add(parsing.batchName);
        return true;
      }

      return false;
    };
  }

  useEffect(() => {
    fetchParsingList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    setList(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.batchName]);

  const filteredParsings = list
    ?.filter(filterInnerParsing())
    .map((parsing) => ({ ...parsing, key: parsing.id }));

  return (
    <div className='basic-layout'>
      <Space direction='vertical' size='large'>
        <ParsingListHeader />
        <Spin spinning={isFetching} tip='Loading...'>
          <Table
            sticky
            size='small'
            columns={columns}
            locale={{
              emptyText: isFetching ? "" : "No parsings found",
            }}
            dataSource={filteredParsings}
            rowSelection={{
              selectedRowKeys: rowSelection.map((parsing) => parsing.id),
              onChange: (_: React.Key[], selectedRows) => {
                setRowSelection(selectedRows);
              },
              getCheckboxProps: (parsing) => ({
                name: parsing.batchName,
              }),
            }}
            pagination={{
              ...pagination,
              onChange: handlePaginationChange,
            }}
            scroll={{
              x: "max-infographic",
              y: "calc(100vh - 300px)",
            }}
          />
        </Spin>
      </Space>
    </div>
  );
}

export default function ParsingListWithContext() {
  return (
    <ParsingProvider>
      <ParsingList />
    </ParsingProvider>
  );
}
