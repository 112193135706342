import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Button, notification, Tag, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Link, useNavigate } from "react-router-dom";
import { SupportedLanguage } from "~/model/enum";
import { INectarQuestion } from "~/model/nectar.model";
import { convertNectarQuestion, deleteNectarQuestion } from "~/services";
import { languageLabel } from "~/utils/helpers";

import { useQuestionsQuery } from "./use-questions-query";
import { IQuery } from "~/model/query.model";

export const useQuestionsColumns = (filters: IQuery | null) => {
  const navigate = useNavigate();

  const { fetch: fetchQuestions } = useQuestionsQuery();

  const handleConvertQuestion = async (questionId: string) => {
    const nectar = await convertNectarQuestion(questionId);

    if (nectar?.response?.data?.statusCode === 400)
      return notification.error({
        message: "Question already converted",
        description: "This question has already been converted to nectar",
        placement: "bottomRight",
      });

    notification.success({
      message: "Question converted to nectar",
      description: "You will be redirected to the nectar page",
    });
    navigate(`/content-management/nectar/${nectar._id}`);
  };

  const handleDeleteQuestion = async (questionId: string) => {
    await deleteNectarQuestion(questionId);

    fetchQuestions(filters || { limit: 20, offset: 0 });

    notification.success({
      message: "Question has been deleted",
      description: "You will no longer see this question in the list",
    });
  };

  const columns: ColumnsType<INectarQuestion> = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 120,
      render: (_, nectarQuestion) =>
        !nectarQuestion.answer ? (
          nectarQuestion?.errorMessage ? (
            <Tooltip title={nectarQuestion.errorMessage}>
              <Tag icon={<CloseOutlined />} color='red'>
                Error
              </Tag>
            </Tooltip>
          ) : (
            <Tag color='orange' icon={<SyncOutlined spin />}>
              In progress
            </Tag>
          )
        ) : (
          <Tag icon={<CheckOutlined />} color='green'>
            Done
          </Tag>
        ),
    },
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
      render: (question: string, nectarQuestion) =>
        nectarQuestion.answer ? (
          <Link
            to={`/content-management/nectar/question/${nectarQuestion._id}`}
          >
            {question}
          </Link>
        ) : (
          question
        ),
    },
    {
      title: "Language",
      dataIndex: "language",
      key: "language",
      width: 100,
      render: (language: SupportedLanguage) => languageLabel[language],
    },
    {
      title: "Organisation",
      dataIndex: ["organisation", "name"],
      key: "organisation",
      width: 300,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      fixed: "right",
      width: 180,
      render: (_, nectarQuestion) => (
        <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
          <Button
            disabled={!nectarQuestion.answer}
            icon={<SyncOutlined />}
            onClick={() => handleConvertQuestion(nectarQuestion._id)}
          >
            Convert
          </Button>
          <Button
            danger
            type='primary'
            icon={<DeleteOutlined />}
            onClick={() =>
              handleDeleteQuestion((nectarQuestion as INectarQuestion).id)
            }
          />
        </div>
      ),
    },
  ];

  return { columns };
};
