import { notification } from "antd";
import { useState } from "react";
import { AskLLmDto } from "~/model/dto/llm-dto";

import { createNectarQuestion } from "~/services";
import { getRequestErrorMessage } from "~/utils/helpers";

export const useAskLlmMutation = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const mutate = async (values: AskLLmDto) => {
    try {
      setLoading(true);
      const res = await createNectarQuestion(values);
      return res;
    } catch (error) {
      notification.error({
        message: "Failed to ask JuisciGPT",
        description: getRequestErrorMessage(error),
      });
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return { mutate, loading, error };
};
