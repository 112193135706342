import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  Skeleton, 
  Spin,
} from "antd";
import { Flex } from "~/components/shared/global";
import { useShortLinkMutation } from "~/hooks/use-short-link-mutation";
import { useShortLinkQuery } from "~/hooks/use-short-link-query";
import { useParams } from "react-router-dom";
import { useRef, useState, useEffect } from "react";
import { useQRCodeEditor, availableThemes, availableSizes } from "../../hooks/qr-code/use-qr-code-editor";
import MultiTagsField from "./MultiTagsField";
import { REDIRECT_HOST } from "./config";
import { ProfessionSelect } from "~/components/ProfessionSelect";
import { MedicalSpecialtySelect } from "~/components/MedicalSpecialtySelect";
const randomString = (length: number) => [...crypto.getRandomValues(new Uint8Array(length))]
   .map((x,i)=>(i=x/255*61|0,String.fromCharCode(i+(i>9?i>35?61:55:48)))).join('');

const parseRedirectToUrl = (
  redirectTo: string | undefined,
  queryParams: string[] = [],
  trackingParams = ['utm_campaign', 'utm_medium', 'utm_content'],
) => {
  const defaultTrackingParams = Object.fromEntries(trackingParams.map(param => [param, '']));
  const defaultQueryParams = Object.fromEntries(queryParams.map(param => [param, '']));
  if (!redirectTo) {
    return {
      redirectToWithoutTracking: redirectTo,
      trackingQueryParams: defaultTrackingParams,
      queryParams: defaultQueryParams,
    };
  }
  const url = new URL(redirectTo);
  const redirectToWithoutParams = new URL(url);
  redirectToWithoutParams.search = '';
  const allQueryParams = Object.fromEntries(url.searchParams.entries());
  
  const trackingQueryParams = Object.fromEntries(
    Object.entries(defaultTrackingParams).map(([key]) => [key, allQueryParams[key] || ''])
  );
  const otherQueryParams = Object.fromEntries(
    Object.entries(defaultQueryParams).map(([key]) => [key, allQueryParams[key] || ''])
  );
  
  trackingParams.forEach(param => url.searchParams.delete(param));
  
  return {
    redirectToWithoutParams: redirectToWithoutParams.toString(),
    redirectToWithoutTracking: url.toString(),
    trackingQueryParams,
    queryParams: otherQueryParams,
  };
};

const makeRedirectToUrl = (redirectTo: string | undefined, queryParams: Record<string, string>) => {
  if (!redirectTo) {
    return redirectTo;
  }
  const url = new URL(redirectTo);
  Object.entries(queryParams).forEach(([key, value]) => {
    if (value) {
      url.searchParams.set(key, value);
    }
  });
  return url.toString();
};


const ConstrainedInput = ({
  name,
  value = '',
  placeholder,
  onChange = () => {},
  style
}: {
  name: string;
  value?: string;
  placeholder: string;
  onChange?: (value: string) => void;
  style?: React.CSSProperties;
}) => {
  if (name === 'profession') {
    return <ProfessionSelect value={value} onChange={onChange} style={style} allowClear />
  }

  if (name === 'medical_speciality') {
    return <MedicalSpecialtySelect value={value} onChange={onChange} style={style} allowClear />
  }

  return <Input value={value} placeholder={placeholder} onChange={(e) => onChange(e.target.value)} style={style} />
};

const ShortLinkEdition = () => {
  const [form] = Form.useForm();
  const params = useParams();
  const pathFromParams = params['*'];
  const isNew = !pathFromParams;
  const path = useRef<string>(pathFromParams ?? randomString(7));
  const shortLinkUrl = `https://${REDIRECT_HOST}/${path.current}`;
  const { data, loading: redirectLoading, redirectExists, refresh } = useShortLinkQuery(path.current);
  const exists = Boolean(data);
  const [pathEditable, setPathEditable] = useState<boolean>(false);
  const [qrCodeEditable, setQrCodeEditable] = useState<boolean>(false);
  const { mutate, loading: mutationLoading } = useShortLinkMutation();
  const { QRCodeEditor, getData: getQRCodeData, setSize, setTheme } = useQRCodeEditor(shortLinkUrl);
  const isInviteLink = data?.redirectTo?.includes('/invite/');
  const { redirectToWithoutParams, trackingQueryParams, queryParams } = parseRedirectToUrl(
    data?.redirectTo,
    isInviteLink ? ['profession', 'medical_speciality'] : []
  );
  const qrCodeImageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
      form.setFieldsValue({
        name: data?.name ?? '',
        path: path.current,
        redirectTo: redirectToWithoutParams || '',
        qrCodeImage: data?.qrCodeImage || '',
        queryParams: queryParams,
        trackingParams: trackingQueryParams,
        tags: data?.tags || [],
      });
  }, [data]);

  const handleSubmit = async () => {
    if (isNew) {
      const exists = await redirectExists(path.current);
      if (exists && confirm("Short link already exists, do you want to overwrite it?") !== true) {
        return;
      }
    }
    const data = form.getFieldsValue();
    const redirectTo = makeRedirectToUrl(data.redirectTo, { ...data.queryParams, ...data.trackingParams });
    await mutate({
      path: data.path,
      name: data.name,
      redirectTo: redirectTo || null,
      qrCodeImage: qrCodeEditable ? await getQRCodeData() as Blob : undefined,
      tags: data.tags,
    });
    refresh();
  };

  return (
    <div className='basic-layout'>
      <Flex align='center' justify='space-between'>
        <h1>
          {isNew ? "Create short link" : "Edit short link"}
        </h1>
        <Button
          type='primary'
          onClick={() => form.submit()}
        >
          {isNew ? "Create" : "Update"}
        </Button>
      </Flex>
      <Divider />
      <Spin spinning={redirectLoading || mutationLoading}>
        <Card>
          <Form
            form={form}
            layout='vertical'
            name='short_link_form'
            onFinish={handleSubmit}
          >
            <Form.Item key='name' label='Name' name='name' required>
              <Input placeholder='Name' />
            </Form.Item>
            <Form.Item key='path' label='Short URL path' required>
              <Flex align='center'>
                <span>https://{REDIRECT_HOST}/</span>
                <Form.Item name='path' noStyle>
                  <Input disabled={!pathEditable} />
                </Form.Item>
                {!exists && <Button type='link' onClick={() => setPathEditable(!pathEditable)}>{ pathEditable ? "Cancel" : "Edit" }</Button>}
              </Flex>
            </Form.Item>
            <Form.Item label='Redirects to' name='redirectTo' required>
              <Input placeholder='URL' value={redirectToWithoutParams} />
            </Form.Item>

            <Card size="small" title="Tracking Parameters" style={{ marginBottom: 16 }}>
              <Form.Item name="trackingParams" noStyle>
                <Flex flexDirection="column" gap={8}>
                  {Object.entries(trackingQueryParams).map(([key]) => (
                    <Flex key={key} gap={8} align="center">
                      <Input
                        value={key}
                        style={{ width: 150 }}
                        disabled
                      />
                      <Form.Item name={['trackingParams', key]} noStyle>
                        <Input placeholder="Value" />
                      </Form.Item>
                    </Flex>
                  ))}
                </Flex>
              </Form.Item>
            </Card>

            <Card size="small" title="Custom Parameters" style={{ marginBottom: 16 }}>
              <Form.Item name="queryParams" noStyle>
                <Flex flexDirection="column" gap={8}>
                  {Object.entries(queryParams).map(([key]) => (
                    <Flex key={key} gap={8} align="center">
                      <Input
                        value={key}
                        style={{ width: 150 }}
                        disabled
                      />
                      <Form.Item name={['queryParams', key]} noStyle>
                        <ConstrainedInput
                          placeholder="Value"
                          name={key}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Flex>
                  ))}
                </Flex>
              </Form.Item>
            </Card>
            
            <Form.Item name='tags' label='Tags'>
              <MultiTagsField form={form} />
            </Form.Item>

            <Form.Item label='QR code' name='qrCodeImage'>
              {redirectLoading ? <Skeleton.Image active /> : !qrCodeEditable ? <>
                {data?.qrCodeImage && <img src={data?.qrCodeImage?.url} ref={qrCodeImageRef} alt='QR code' />}
                <Button type='link' onClick={() => setQrCodeEditable(!qrCodeEditable)}>{!data?.qrCodeImage ? "Create QR code" : "Edit QR code"}</Button>
              </> : <>
                <Flex gap={8}>
                  {availableThemes.map((theme) => (
                    <Button key={theme} type='link' onClick={() => setTheme(theme)}>{theme}</Button>
                  ))}
                </Flex>
                <Flex gap={8}>
                  {availableSizes.map((size) => (
                    <Button key={size} type='link' onClick={() => setSize(size)}>{size}</Button>
                  ))}
                </Flex>
                <QRCodeEditor />
              </>}
            </Form.Item>
          </Form>
        </Card>
      </Spin>
    </div>
  );
};

export default ShortLinkEdition;
