import { Divider, Table } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { NectarFilterDrawer } from "~/components/nectar/NectarFilterDrawer";
import { Flex } from "~/components/shared/global";
import { IQuery } from "~/model/query.model";
import { useQuestionsColumns } from "~/hooks/questions/use-questions-columns";
import { useNectarContext } from "~/hooks/nectars/use-nectar-context";
import { useQuestionsQuery } from "~/hooks/questions/use-questions-query";
import { useSearchParams } from "react-router-dom";
import { SupportedLanguage } from "~/model/enum";

const FETCH_LIMIT = 20;
const INIT_FILTERS: IQuery = {
  sortBy: "creationDate",
  sortOrder: "desc",
  limit: FETCH_LIMIT,
  offset: 0,
};

export default function NectarQuestinList() {
  const intervalRef = useRef<NodeJS.Timeout>();
  const { questions, activeTab } = useNectarContext();
  const { fetch: fetchQuestions, loading } = useQuestionsQuery();

  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState<IQuery | null>({
    ...INIT_FILTERS,
    languages:
      (searchParams.get("languages") as unknown as SupportedLanguage[]) ||
      undefined,
  });
  const { columns } = useQuestionsColumns(filters);

  const saveLanguageFilterInUrl = useCallback(() => {
    if (!filters?.languages || !filters.languages?.length)
      searchParams.delete("languages");
    else searchParams.set("languages", filters.languages as unknown as string);

    setSearchParams(searchParams);
  }, [filters, searchParams, setSearchParams]);

  const handleChangePage = useCallback(
    (page: number, pageSize?: number) => {
      setFilters({
        ...filters,
        offset: (page - 1) * (pageSize || FETCH_LIMIT),
        limit: pageSize || FETCH_LIMIT,
      });
    },
    [filters]
  );

  const handleChangeFilters = (values: IQuery) => {
    setFilters({ ...filters, ...values });
  };

  useEffect(() => {
    if (!filters || loading) return;

    fetchQuestions(filters);

    saveLanguageFilterInUrl();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    if (activeTab !== "questions" || !filters) {
      clearInterval(intervalRef.current);
      return;
    }

    intervalRef.current = setInterval(() => {
      fetchQuestions(filters);
    }, 5000);

    return () => clearInterval(intervalRef.current as NodeJS.Timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, activeTab]);

  return (
    <div>
      <Flex align='center' justify='space-between' gap={16}>
        <h1>{"Questions"}</h1>
        <NectarFilterDrawer
          defaultValues={filters || undefined}
          onChange={handleChangeFilters}
        />
      </Flex>
      <Divider />
      <Table
        size='small'
        scroll={{ x: "max-content", y: "calc(100vh - 300px)" }}
        pagination={{
          current: filters?.offset
            ? filters.offset / (filters?.limit || FETCH_LIMIT) + 1
            : 1,
          pageSize: filters?.limit || FETCH_LIMIT,
          pageSizeOptions: [10, 20, 50, 100],
          total: questions?.meta.total || 0,
          showSizeChanger: true,
          onChange: (page, pageSize) => handleChangePage(page, pageSize),
        }}
        columns={columns}
        loading={!questions}
        dataSource={
          questions
            ? questions.docs.map((question) => ({
                key: question._id,
                ...question,
              }))
            : []
        }
      />
    </div>
  );
}
