/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  notification,
  Row,
  Spin,
  Tabs,
} from "antd";

import { red } from "@ant-design/colors";

import {
  addArticleToContent,
  deleteArticleFromContent,
  getVideo,
  searchArticlesV2,
} from "../../../services";

import { useLocation, useParams } from "react-router-dom";
import LoadingLayout from "../../../components/shared/LoadingLayout";
import RoomsAssociator from "../../../components/shared/RoomsAssociator";
import VideoEditionContentForm from "./VideoEditionContentForm";
import EditionPageHeader, {
  PageHeaderSetContent,
} from "~/components/EditionPageHeader.tsx";
import { IVideo } from "~/model/video.model";
import { IArticle } from "~/model/article.model";
import { ContentFormatsEnum } from "~/model/enum";

export const VideoEdition: React.FC = () => {
  const params = useParams();
  const location = useLocation();
  const videoId = params.id;
  const isCreationMode = location.pathname.includes("create");

  const [form] = Form.useForm();

  const [innerVideo, setInnerVideo] = useState<IVideo | null>(null);
  const [videoSaving, setVideoSaving] = useState<boolean>(false);

  const [searchArticleList, setSearchArticleList] = useState<IArticle[]>([]);
  const [searchValue, setSearchValue] = useState<string>();
  const { TabPane } = Tabs;

  const fetchArticles = async () => {
    if (!searchValue) return;

    const articleList = await searchArticlesV2({
      limit: 10,
      offset: 0,
      languages: innerVideo?.language ? [innerVideo?.language] : undefined,
      status: "published",
      search: searchValue,
    });

    if (articleList.hits.hits) {
      setSearchArticleList(
        articleList.hits.hits.map(
          (article: { _source: { core: IArticle; id: string } }) => ({
            ...article._source.core,
            _id: article._source.id,
          })
        )
      );
    }
  };

  const fetchInnerVideo = async () => {
    if (videoId && !innerVideo) {
      const video = await getVideo(videoId);
      setVideoSaving(false);

      if (video) setInnerVideo(video);
    }
  };

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
  };

  const handleAssociatedArticleAdd = async (articleId: string) => {
    if (innerVideo) {
      const updatedVideo = await addArticleToContent(innerVideo._id, articleId);

      setInnerVideo({
        ...innerVideo,
        associatedArticles: updatedVideo.associatedArticles,
      });

      notification.success({
        message: "Article added to video successfuly",
      });
    }
  };

  const handleAssociatedArticleRemove = async (articleId: string) => {
    if (innerVideo) {
      const updatedVideo = await deleteArticleFromContent(
        innerVideo._id,
        articleId
      );

      setInnerVideo({
        ...innerVideo,
        associatedArticles: updatedVideo.associatedArticles,
      });

      notification.success({
        message: "Article removed from video successfuly",
      });
    }
  };

  useEffect(() => {
    fetchArticles();
  }, [searchValue]);

  useEffect(() => {
    fetchInnerVideo();
  }, [videoId]);

  useEffect(() => {
    fetchInnerVideo();
  }, [videoSaving]);

  if (!innerVideo && !isCreationMode) return <LoadingLayout />;

  return (
    <Layout>
      <Layout.Content style={{ padding: "50px" }}>
        <EditionPageHeader
          form={form}
          format={ContentFormatsEnum.VIDEO}
          content={innerVideo as IVideo}
          setContent={setInnerVideo as PageHeaderSetContent}
        />

        <div className='site-layout-content'>
          <Spin spinning={videoSaving} tip='Video saving...'>
            <Tabs defaultActiveKey='1'>
              <TabPane tab='Details' key='1'>
                <VideoEditionContentForm form={form} video={innerVideo} />
              </TabPane>
              <TabPane tab='Articles' key='2'>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "20px 0",
                  }}
                >
                  <Col span={11}>
                    <h3 style={{ marginBottom: "20px" }}>Current articles</h3>
                    {innerVideo?.associatedArticles?.map(
                      (article: IArticle) => (
                        <Card
                          key={article.slug}
                          style={{ marginBottom: "20px" }}
                        >
                          <p>{article.title}</p>
                          <Button
                            style={{
                              color: "white",
                              backgroundColor: red.primary,
                              float: "right",
                            }}
                            onClick={() =>
                              handleAssociatedArticleRemove(article._id)
                            }
                          >
                            Remove
                          </Button>
                        </Card>
                      )
                    )}
                  </Col>
                  <Col span={11}>
                    <h3 style={{ marginBottom: "20px" }}>Article list</h3>
                    <Input.Search
                      placeholder='Search article'
                      allowClear
                      style={{
                        marginBottom: "20px",
                      }}
                      onSearch={handleSearchChange}
                    />
                    {searchArticleList?.map((article: IArticle) => (
                      <Card key={article.slug} style={{ marginBottom: "20px" }}>
                        <p>{article.title}</p>
                        <Button
                          type='primary'
                          className='info-form-button'
                          style={{ float: "right" }}
                          onClick={() =>
                            handleAssociatedArticleAdd(article._id)
                          }
                          disabled={innerVideo?.associatedArticles.some(
                            (associatedArticle: IArticle) =>
                              associatedArticle._id === article._id
                          )}
                        >
                          Add new article
                        </Button>
                      </Card>
                    ))}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab='Rooms' key='3'>
                {innerVideo && (
                  <RoomsAssociator
                    contentId={innerVideo._id}
                    language={innerVideo.language}
                    type={ContentFormatsEnum.VIDEO}
                    ownerId={innerVideo.owner?._id}
                    isPublic={innerVideo.isPublic}
                    form={form}
                  />
                )}
              </TabPane>
            </Tabs>
          </Spin>
        </div>
      </Layout.Content>
    </Layout>
  );
};
