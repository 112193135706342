import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Card, Col, notification, Row } from "antd";
import { red } from "@ant-design/colors";
import { ITag } from "~/model/tag.model";
import { ContentFormatsEnum } from "~/model/enum";
import { ContentTabPaneModel } from "./constant";
import { DragOutlined } from "@ant-design/icons";
import {
  deleteContentFromPlaylist,
  removePlaylistArticle,
  updateArticleOrderPlaylist,
} from "~/services";
import { getRequestErrorMessage } from "~/utils/helpers";
import { usePlaylistContext } from "~/hooks/playlists/use-playlists-content";
import { GridContextProvider, GridDropZone, GridItem } from "react-grid-dnd";
import { Link } from "react-router-dom";

type PlaylistContentCardProps = {
  tabPane: ContentTabPaneModel;
};

export const PlaylistContentList = ({ tabPane }: PlaylistContentCardProps) => {
  const debouceRef = useRef<NodeJS.Timeout | null>(null);
  const params = useParams();
  const playlistId = params.id;
  const { activePane, setIsLoading, playlist, setPlaylist } =
    usePlaylistContext();
  const [isDragging, setIsDragging] = useState(false);

  const handleDeleteContent = async (contentId: string) => {
    if (!playlistId)
      return notification.error({
        message: "The playlist must be created before removing an article.",
        description: "Please create a playlist first.",
      });

    try {
      setIsLoading(true);
      const newPlaylist =
        activePane === ContentFormatsEnum.ARTICLE
          ? await removePlaylistArticle(playlistId, contentId)
          : await deleteContentFromPlaylist({
              contentId,
              playlistId,
            });

      if (newPlaylist) {
        setPlaylist({
          ...newPlaylist,
          medical_specialties: (newPlaylist.medical_specialties as ITag[]).map(
            (tag) => tag.uid
          ),
          tags: (newPlaylist.tags as ITag[]).map((tag) => tag.uid),
        });
      }

      notification.success({
        message: "Content removed successfully",
        description: "The content has been removed from the playlist.",
      });
    } catch (error) {
      notification.error({
        message: "An error occurred while removing the content",
        description: getRequestErrorMessage(error),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateArticlesOrder = async (articles: string[]) => {
    if (!playlistId || !playlist) return;

    try {
      await updateArticleOrderPlaylist(articles, playlistId);
    } catch (error) {
      notification.error({
        message: "An error occurred while reordering the articles",
        description: getRequestErrorMessage(error),
      });
      console.error(error);
    }
  };

  const handleDragChange = (
    _sourceId: string,
    sourceIndex: number,
    targetIndex: number
  ) => {
    if (!playlist || sourceIndex === targetIndex) return;

    const newPlaylist = [...playlist.playlist];

    const [removedElement] = newPlaylist.splice(sourceIndex, 1);
    newPlaylist.splice(targetIndex, 0, removedElement);

    setPlaylist({
      ...playlist,
      playlist: newPlaylist,
    });

    if (debouceRef.current) clearTimeout(debouceRef.current);

    debouceRef.current = setTimeout(() => {
      const articles = newPlaylist.map((article) => article._id);
      handleUpdateArticlesOrder(articles);
    }, 2000);
  };

  const rowHeight = 220;
  const items = tabPane.contentList;
  const isArticle = tabPane.type === ContentFormatsEnum.ARTICLE;
  const disableDrag = !isArticle || !isDragging;

  if (!items) return null;

  return (
    <div style={{ width: "100%" }}>
      <GridContextProvider onChange={handleDragChange}>
        <GridDropZone
          id='items'
          boxesPerRow={1}
          rowHeight={rowHeight}
          disableDrag={disableDrag}
          style={{
            height: rowHeight * items?.length,
            cursor: "auto",
          }}
        >
          {items?.map((content) => (
            <GridItem key={content.id}>
              <Row
                gutter={16}
                key={content.slug + "current-content"}
                style={{ height: "calc(100% - 16px)", cursor: "auto" }}
              >
                {isArticle ? (
                  <Col span={4}>
                    <Card
                      onMouseDown={() => setIsDragging(true)}
                      onMouseUp={() => setIsDragging(false)}
                      style={{ height: "100%" }}
                      bodyStyle={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        cursor: "move",
                      }}
                    >
                      <DragOutlined />
                    </Card>
                  </Col>
                ) : null}

                <Col flex='1'>
                  <Card style={{ height: "100%" }}>
                    <div
                      style={{
                        minHeight: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <Link
                        to={`/content-management/${tabPane.type.toLowerCase()}/${
                          tabPane.type === ContentFormatsEnum.VIDEO
                            ? content.slug
                            : content._id
                        }`}
                      >
                        {content.title}
                      </Link>
                      <div>
                        <Button
                          style={{
                            color: "white",
                            backgroundColor: red.primary,
                            float: "right",
                          }}
                          htmlType='button'
                          onClick={() =>
                            handleDeleteContent(
                              activePane === ContentFormatsEnum.ARTICLE
                                ? content.slug
                                : content._id
                            )
                          }
                        >
                          Remove
                        </Button>
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
            </GridItem>
          ))}
        </GridDropZone>
      </GridContextProvider>
    </div>
  );
};
