
import { Select, SelectProps } from "antd";
import { useContext } from "react";
import { GlobalContext } from "~/context/global.context";
import { SupportedLanguage } from "~/model/enum";
import { searchFilterOptions } from "~/utils/helpers";


export const MedicalSpecialtySelect = (selectProps: SelectProps) => {
  const { tagList } = useContext(GlobalContext);
  const medicalSpecialties = tagList.filter((tag) => !tag.parent);
  return <Select
    placeholder='Select medical specialties'
    disabled={!tagList.length}
    loading={!tagList.length}
    options={medicalSpecialties.map((medicalSpecialty) => ({
      label: medicalSpecialty.translations[SupportedLanguage.EN],
      value: medicalSpecialty._id,
    }))}
    filterOption={searchFilterOptions}
    {...selectProps}
  />;
};
