import React, { useEffect, useState } from "react";
import { Form, Spin, Tabs } from "antd";
import {
  getPlaylist,
  searchArticlesV2,
  searchVideos,
  searchNectar,
} from "../../../services";
import { useLocation, useParams } from "react-router-dom";
import RoomsAssociator from "../../../components/shared/RoomsAssociator";
import { FiltersDrawerValuesModel } from "../../../components/shared/FiltersDrawer";
import PlaylistEditionContentForm from "../../../components/playlist/PlaylistEditionContentForm";
import LoadingLayout from "~/components/shared/LoadingLayout";
import EditionPageHeader, {
  PageHeaderSetContent,
} from "~/components/EditionPageHeader.tsx";

import { ContentFormatsEnum, SupportedLanguage } from "~/model/enum";
import { IContent } from "~/model/content.model";
import { IArticle } from "~/model/article.model";
import { getContentTabPanes } from "~/components/playlist/constant";
import { usePlaylistContext } from "~/hooks/playlists/use-playlists-content";
import { PlaylistProvider } from "~/context/playlist.context";
import { PlaylistContentTabPanes } from "~/components/playlist/PlaylistContentTabPanes";

const PlaylistEditionWithoutContext: React.FC = () => {
  const params = useParams();
  const location = useLocation();
  const playlistId = params.id;
  const search = new URLSearchParams(location.search);

  const {
    playlist,
    setPlaylist,
    setSearchList,
    searchValue,
    setSearchValue,
    activePane,
    setActivePane,
    isLoading,
  } = usePlaylistContext();

  const [language, setLanguage] = useState<SupportedLanguage | undefined>(
    Object.values(SupportedLanguage).includes(
      search.get("language") as SupportedLanguage
    )
      ? (search?.get("language") as SupportedLanguage)
      : SupportedLanguage.EN
  );

  const [form] = Form.useForm();
  const { TabPane } = Tabs;

  const fetchPlaylist = async () => {
    if (playlistId && !playlist) {
      const _playlist = await getPlaylist(playlistId);

      setPlaylist(_playlist);
      setLanguage(_playlist.language);
    }
  };

  const fetchArticles = async (limit?: number, offset?: number) => {
    if (!searchValue) return;

    const articleList = await searchArticlesV2({
      limit: limit || 10,
      offset: offset || 0,
      languages: language ? [language] : undefined,
      status: "published",
      search: searchValue,
    });

    if (articleList.hits.hits) {
      setSearchList(
        articleList.hits.hits.map(
          (article: { _source: { core: IArticle } }) => article._source.core
        )
      );
    }
  };

  const fetchVideos = async (limit?: number, offset?: number) => {
    if (!searchValue) return;

    const videoList = await searchVideos(searchValue, {
      limit: limit || 10,
      offset: offset || 0,
      status: "published" as FiltersDrawerValuesModel["status"],
      languages: language ? [language] : undefined,
    });

    if (videoList.hits) {
      setSearchList(
        videoList.hits.map(
          (video: { _source: { view: IContent; id: string } }) => ({
            ...video._source.view,
            id: video._source.id,
          })
        )
      );
    }
  };

  const fetchNectars = async () => {
    if (!searchValue) return;

    const { docs: nectarList } = await searchNectar(searchValue);

    if (nectarList) setSearchList(nectarList);
    else setSearchList([]);
  };

  useEffect(() => {
    fetchPlaylist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playlistId]);

  useEffect(() => {
    if (!searchValue) setSearchList([]);
    else if (activePane === ContentFormatsEnum.ARTICLE) fetchArticles();
    else if (activePane === ContentFormatsEnum.VIDEO) fetchVideos();
    else fetchNectars();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if (
      activePane === ContentFormatsEnum.ARTICLE ||
      activePane === ContentFormatsEnum.VIDEO
    ) {
      setSearchValue("");
      setSearchList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePane]);

  useEffect(() => {
    if (!playlist) return;

    form.setFieldValue("playlist", playlist.playlist);
  }, [playlist, form]);

  const contentTabPanes = getContentTabPanes(playlist);

  if (!playlist && playlistId) return <LoadingLayout />;

  return (
    <div className='basic-layout'>
      <EditionPageHeader
        form={form}
        format={ContentFormatsEnum.PLAYLIST}
        content={playlist}
        setContent={setPlaylist as PageHeaderSetContent}
      />

      <div className='site-layout-content'>
        <Spin spinning={isLoading} tip='Playlist saving...'>
          <Tabs
            defaultActiveKey={"1"}
            onChange={(activeKey) => setActivePane(activeKey)}
          >
            <TabPane tab='Details' key='1'>
              <PlaylistEditionContentForm
                form={form}
                playlist={playlist}
                setPlaylist={setPlaylist}
              />
            </TabPane>

            <TabPane tab='Rooms' key={"2"}>
              {playlistId && playlist && (
                <RoomsAssociator
                  contentId={playlistId}
                  language={playlist.language}
                  type={"playlist"}
                  isPublic={playlist?.isPublic}
                  ownerId={playlist?.owner?._id}
                  form={form}
                />
              )}
            </TabPane>

            {contentTabPanes.map((tabPane) => (
              <TabPane tab={tabPane.title} key={tabPane.type}>
                <PlaylistContentTabPanes tabPane={tabPane} />
              </TabPane>
            ))}
          </Tabs>
        </Spin>
      </div>
    </div>
  );
};

export default function PlaylistEdition() {
  return (
    <PlaylistProvider>
      <PlaylistEditionWithoutContext />
    </PlaylistProvider>
  );
}
