
import {
  DrawType,
  TypeNumber,
  Mode,
  ErrorCorrectionLevel,
  DotType,
  CornerSquareType,
  CornerDotType,
  Options
} from "qr-code-styling";

const defaultOptions: Options = {
  type: 'png' as DrawType,
  margin: 10,
  qrOptions: {
    typeNumber: 0 as TypeNumber,
    mode: 'Byte' as Mode,
    errorCorrectionLevel: 'Q' as ErrorCorrectionLevel
  },
  dotsOptions: {
    color: '#000',
    type: 'square' as DotType
  },
  backgroundOptions: {
    color: '#fff',
  },
  cornersSquareOptions: {
    color: '#000',
    type: 'square' as CornerSquareType,
  },
  cornersDotOptions: {
    color: '#000',
    type: 'square' as CornerDotType,
  }
};

const juisciLogoOptions = {
  image: '/juisci-logo-tube.png',
  imageOptions: {
    hideBackgroundDots: true,
    imageSize: 0.4,
    margin: 4,
    crossOrigin: 'anonymous',
  },
}

export const availableThemes = ['default', 'with-logo', 'rounded', 'rounded-with-logo', 'dotted', 'dotted-with-logo'] as const;
export const availableSizes = [200, 400, 800, 1000] as const;

export type Theme = typeof availableThemes[number];
export type Size = typeof availableSizes[number];

export const qrConfigs: Record<Theme, Options> = {
  'default': defaultOptions,
  'with-logo': {
    ...defaultOptions,
    ...juisciLogoOptions
  },
  'rounded': {
    ...defaultOptions,
    cornersSquareOptions: {
      ...defaultOptions.cornersSquareOptions,
      type: 'rounded' as CornerSquareType,
    },
  },
  'rounded-with-logo': {
    ...defaultOptions,
    ...juisciLogoOptions,
    cornersSquareOptions: {
      ...defaultOptions.cornersSquareOptions,
      type: 'rounded' as CornerSquareType,
    },
  },
  'dotted': {
    ...defaultOptions,
    dotsOptions: {
      ...defaultOptions.dotsOptions,
      type: 'dots' as DotType
    },
    cornersDotOptions: {
      ...defaultOptions.cornersDotOptions,
      type: undefined,
    },
    cornersSquareOptions: {
      ...defaultOptions.cornersSquareOptions,
      type: undefined,
    },
  },
  'dotted-with-logo': {
    ...defaultOptions,
    ...juisciLogoOptions,
    dotsOptions: {
      ...defaultOptions.dotsOptions,
      type: 'dots' as DotType
    },
    cornersDotOptions: {
      ...defaultOptions.cornersDotOptions,
      type: undefined,
    },
    cornersSquareOptions: {
      ...defaultOptions.cornersSquareOptions,
      type: undefined,
    },
  },
}
