import {
  DownloadOutlined,
  FileOutlined,
  SnippetsOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Alert, Button, Card, message, notification, Space } from "antd";
import { Flex } from "../shared/global";
import { IParsing } from "~/model/parser.model";
import { ParsingStatusEnum } from "~/model/enum";
import { useState } from "react";
import { parseArticleFromDoi, postRetryParsing } from "~/services";
import { useNavigate } from "react-router-dom";
import { getRequestErrorMessage } from "~/utils/helpers";

const parsingAPI = import.meta.env.VITE_APP_PARSING_API;

type ParsingResultCardProps = { parsing?: IParsing };

export const ParsingResultCard = ({ parsing }: ParsingResultCardProps) => {
  const navigate = useNavigate();
  const parsingId = parsing?.id;
  const isSummarized = parsing?.result?.summarized && !parsing?.article;

  const [isRetryParsing, setIsRetryParsing] = useState<boolean>(false);

  const handleRetryParsing = async () => {
    if (!parsing?.taskId) return message.error("Associated Task ID not found");

    setIsRetryParsing(true);

    try {
      let res;

      if (parsing?.doi)
        res = await parseArticleFromDoi({ doiList: [parsing.doi] });
      else res = await postRetryParsing(parsing?.taskId);

      navigate(-1);

      if (res)
        notification.success({
          message: "Parsing retry",
          description: "Parsing was successfully retried",
        });
      else throw new Error("Parsing retry failed");
    } catch (error) {
      notification.error({
        message: "An error occurred while retrying parsing",
        description: getRequestErrorMessage(error),
      });
    } finally {
      setIsRetryParsing(false);
    }
  };

  return (
    <Card
      style={{
        minHeight: "100%",
        opacity: parsingId ? 1 : 0.5,
        pointerEvents: parsingId ? "all" : "none",
      }}
      title={
        <h3>
          <FileOutlined style={{ marginRight: 8 }} /> {"Results"}
        </h3>
      }
    >
      {parsing?.result && parsing?.article ? (
        <Flex gap={8}>
          <Button
            disabled={!parsing.article._id}
            icon={<SnippetsOutlined />}
            href={`/content-management/article/${parsing.article._id}`}
            block
          >
            View Article
          </Button>

          <Button
            download
            disabled={!parsing?.result?.summarized?.doi}
            href={`${parsingAPI}/api/v1/download_pdf?doi=${parsing?.result?.summarized?.doi}`}
            target='_blank'
            block
          >
            Download PDF <DownloadOutlined />
          </Button>
        </Flex>
      ) : parsing?.meta.status === "success" && !parsing?.result ? (
        <Alert showIcon message='Parser returned no result' type='error' />
      ) : parsing?.meta.status === "success" &&
        Object.keys(parsing.result).includes("detail") ? (
        <Alert showIcon message='Parser returned an error' type='error' />
      ) : isSummarized ? (
        <Space direction='vertical'>
          {parsing.meta.status === ParsingStatusEnum.DUPLICATE ? (
            <Alert
              showIcon
              message='The article already exists in the database:'
              description={parsing?.result?.title}
              type='warning'
            />
          ) : (
            <Alert
              message='This parsing has been summarized, you can create an article.'
              description={
                <Button
                  style={{ padding: 0 }}
                  type='link'
                  icon={<SyncOutlined />}
                  onClick={handleRetryParsing}
                  loading={isRetryParsing}
                  disabled={isRetryParsing}
                >{`Try to create article`}</Button>
              }
            />
          )}
        </Space>
      ) : (
        <Alert
          type='error'
          showIcon
          message='No result to be shown'
          description={
            <Button
              style={{ padding: 0 }}
              type='link'
              icon={<SyncOutlined />}
              onClick={handleRetryParsing}
              loading={isRetryParsing}
              disabled={isRetryParsing}
            >{`Try to create article`}</Button>
          }
        />
      )}
    </Card>
  );
};
