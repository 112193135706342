import { PaginationProps } from "antd";
import { createContext, useState } from "react";
import { IParsing } from "~/model/parser.model";

type ParsingContextType = {
  list: IParsing[] | null;
  setList: (list: IParsing[] | null) => void;
  pagination: PaginationProps;
  setPagination: (pagination: PaginationProps) => void;
  rowSelection: IParsing[];
  setRowSelection: (rowSelection: IParsing[]) => void;
  isFetching: boolean;
  setIsFetching: (isFetching: boolean) => void;
};

export const ParsingContext = createContext<ParsingContextType | null>(null);

export const ParsingProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [list, setList] = useState<IParsing[] | null>(null);
  const [pagination, setPagination] = useState<PaginationProps>({
    pageSize: 50,
    pageSizeOptions: ["50", "75", "100"],
  });
  const [rowSelection, setRowSelection] = useState<IParsing[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  return (
    <ParsingContext.Provider
      value={{
        list,
        setList,
        pagination,
        setPagination,
        rowSelection,
        setRowSelection,
        isFetching,
        setIsFetching,
      }}
    >
      {children}
    </ParsingContext.Provider>
  );
};
