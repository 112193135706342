import { Button, Card, Col, Input, notification, Row } from "antd";
import { ContentTabPaneModel } from "./constant";
import { ContentFormatsEnum } from "~/model/enum";
import { usePlaylistContext } from "~/hooks/playlists/use-playlists-content";
import { useParams } from "react-router-dom";
import { addContentToPlaylist, addPlaylistArticle } from "~/services";
import { ITag } from "~/model/tag.model";
import { getRequestErrorMessage } from "~/utils/helpers";
import { Flex } from "../shared/global";
import { PlaylistContentList } from "./PlaylistContentList";

type PlaylistContentTabPanesProps = {
  tabPane: ContentTabPaneModel;
};

export const PlaylistContentTabPanes = ({
  tabPane,
}: PlaylistContentTabPanesProps) => {
  const params = useParams();
  const playlistId = params.id;
  const {
    searchList,
    setSearchValue,
    activePane,
    setIsLoading,
    playlist,
    setPlaylist,
  } = usePlaylistContext();

  const handleAddContent = async (contentId: string) => {
    if (!playlistId)
      return notification.error({
        message: "The playlist must be created before adding an article.",
        description: "Please create a playlist first.",
      });

    setIsLoading(true);
    try {
      const newPlaylist =
        activePane === ContentFormatsEnum.ARTICLE
          ? await addPlaylistArticle(playlistId, contentId)
          : await addContentToPlaylist({ contentId, playlistId });

      if (playlist && newPlaylist) {
        setPlaylist({
          ...newPlaylist,
          medical_specialties: (newPlaylist.medical_specialties as ITag[]).map(
            (tag: ITag) => tag.uid
          ),
          tags: (newPlaylist.tags as ITag[]).map((tag: ITag) => tag.uid),
        });
      }

      notification.success({
        message: "Content added successfully",
        description: "The content has been added to the playlist.",
      });
    } catch (error) {
      notification.error({
        message: "An error occurred while adding the content",
        description: getRequestErrorMessage(error),
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Row
      style={{
        display: "flex",
        justifyContent: "space-between",
        margin: "20px 0",
      }}
    >
      <Col span={11}>
        <h3 style={{ marginBottom: "20px" }}>{`Current ${tabPane.title}s`}</h3>
        <Flex flexDirection='column' gap={24}>
          <PlaylistContentList tabPane={tabPane} />
        </Flex>
      </Col>
      <Col span={11}>
        <h3 style={{ marginBottom: "20px" }}>{`${tabPane.title}s list`}</h3>
        <Input.Search
          placeholder={`Search ${tabPane.title}`}
          allowClear
          style={{
            marginBottom: "20px",
          }}
          onChange={(e) => setSearchValue(e.target.value)}
          onSearch={(value) => setSearchValue(value)}
        />
        {searchList?.map((content) => (
          <Card
            key={content.title + "search-content"}
            style={{ marginBottom: "20px" }}
          >
            <p>{content.title}</p>
            <Button
              type='primary'
              htmlType='button'
              className='info-form-button'
              style={{ float: "right" }}
              onClick={() =>
                handleAddContent(
                  activePane === ContentFormatsEnum.ARTICLE
                    ? content.slug
                    : content.id
                )
              }
            >
              Add new content
            </Button>
          </Card>
        ))}
      </Col>
    </Row>
  );
};
