import { useCallback, useState } from "react";
import { useNectarContext } from "./use-nectar-context";
import { getListNectar, getNectarQuestionList, searchNectar } from "~/services";
import { notification } from "antd";
import { getRequestErrorMessage } from "~/utils/helpers";
import { IQuery } from "~/model/query.model";

export const useNectarsQuery = () => {
  const { setNectars } = useNectarContext();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const fetch = useCallback(async (params: IQuery) => {
    try {
      setLoading(true);
      const res = await getListNectar(params);
      setNectars(res);
    } catch (error) {
      setError(true);
      notification.error({
        message: "Failed to fetch nectars",
        description: getRequestErrorMessage(error),
      });
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const search = useCallback(async (term: string, params?: IQuery) => {
    try {
      setLoading(true);
      const res = await searchNectar(term, params);
      setNectars(res);
    } catch (error) {
      setError(true);
      notification.error({
        message: "Failed to search nectars",
        description: getRequestErrorMessage(error),
      });
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loading, error, fetch, search };
};

getNectarQuestionList;
