import React, { useEffect, useState } from "react";
import { Button, Input, Layout, Row, Select, Table } from "antd";

import { listOrganisations } from "~/services";
import * as _ from "lodash";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { languageOptions } from "~/utils/helpers";
import { ColumnsType } from "antd/lib/table";
import { IOrganisation } from "~/model/organisation.model";
import { SupportedLanguage } from "~/model/enum";

export const OrganisationList: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const [innerOrganisationList, setInnerOrganisationList] = useState<
    IOrganisation[]
  >([]);
  const [searchValue, setSearchValue] = useState<string>();
  const [language, setLanguage] = useState<SupportedLanguage | undefined>(
    Object.values(SupportedLanguage).includes(
      search.get("language") as SupportedLanguage
    )
      ? (search?.get("language") as SupportedLanguage)
      : SupportedLanguage.EN
  );

  const fetch = async () => {
    const { docs: organisationList } = await listOrganisations({ language });
    setInnerOrganisationList(organisationList);
  };

  const handleLanguageChange = (language: SupportedLanguage) => {
    navigate(`/sensitive-data/organisations?language=${language}`);
    setLanguage(language);
  };

  const handleClick = () => {
    navigate(`/sensitive-data/organisations/create/?language=${language}`);
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const columns: ColumnsType<IOrganisation> = [
    {
      title: "Name",
      dataIndex: "uid",
      key: "uid",
      render: (text: string) => {
        const organisation = _.find(innerOrganisationList, { uid: text });
        if (organisation) {
          return (
            <Link to={`/sensitive-data/organisations/${organisation._id}`}>
              {organisation.name}
            </Link>
          );
        }
      },
    },
  ];

  return (
    <Layout
      style={{
        overflow: "hidden",
        boxSizing: "border-box",
        height: "100vh",
      }}
    >
      <Layout.Content
        style={{
          padding: "20px 50px 50px",
          boxSizing: "border-box",
        }}
      >
        <Row justify='space-between' style={{ margin: "20px 0" }}>
          <h1 style={{ fontWeight: 800, fontSize: "30px" }}>Organisations</h1>
          <div>
            <Select
              defaultValue={language}
              style={{ marginRight: 10 }}
              onSelect={handleLanguageChange}
              options={languageOptions}
            />
            <Button type='primary' onClick={handleClick}>
              New Organisation
            </Button>
          </div>
        </Row>
        <Input.Search
          placeholder='Search Organisation'
          allowClear
          onSearch={setSearchValue}
        />
        <div style={{ height: 16 }} />
        <Table
          size='small'
          columns={columns}
          dataSource={innerOrganisationList.filter((v) =>
            v.name.toLowerCase().includes(searchValue?.toLowerCase() ?? "")
          )}
          scroll={{
            x: "max-infographic",
            y: "calc(100vh - 300px)",
          }}
          sticky
          pagination={{
            showSizeChanger: true,
            defaultPageSize: 20,
            defaultCurrent: 1,
            pageSizeOptions: ["10", "20", "50", "75"],
          }}
        />
      </Layout.Content>
    </Layout>
  );
};
