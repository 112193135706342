import { createContext, useState } from "react";
import { IArticle } from "~/model/article.model";
import { IContent } from "~/model/content.model";
import { IPlaylist } from "~/model/playlist.model";

type PlaylistContextType = {
  playlist: IPlaylist | undefined;
  setPlaylist: (playlist: IPlaylist) => void;

  searchList: IContent[] | IArticle[];
  setSearchList: (searchList: IContent[] | IArticle[]) => void;

  searchValue: string;
  setSearchValue: (searchValue: string) => void;

  activePane: string;
  setActivePane: (activePane: string) => void;

  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
};

export const PlaylistContext = createContext<PlaylistContextType | null>(null);

export const PlaylistProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [playlist, setPlaylist] = useState<IPlaylist>();
  const [searchList, setSearchList] = useState<IContent[] | IArticle[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [activePane, setActivePane] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <PlaylistContext.Provider
      value={{
        playlist,
        setPlaylist,
        searchList,
        setSearchList,
        searchValue,
        setSearchValue,
        activePane,
        setActivePane,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </PlaylistContext.Provider>
  );
};
