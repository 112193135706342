import {
  EyeInvisibleOutlined,
  EyeOutlined,
  FilePdfOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { Space, Tag, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";
import { ParsingStatusTag } from "~/components/parsing/ParsingStatusTag";
import { Flex } from "~/components/shared/global";
import { IParsing } from "~/model/parser.model";

const columns: ColumnsType<IParsing> = [
  {
    title: !window.location.pathname.includes("/batch/")
      ? "Parsing name"
      : "Batch / Parsing Name",
    dataIndex: "type",
    key: "type",
    width: "50%",
    render: (_, parsing) => {
      const searchParams = new URLSearchParams(window.location.search);

      if (parsing?.batchName && !window.location.pathname.includes("/batch/"))
        return (
          <Link
            to={`/content-sources/parsing/batch/${encodeURIComponent(
              parsing.batchName
            )}?offset=0&limit=${searchParams.get("limit") || 50}`}
          >
            <Flex justify='start' align='center' gap={4}>
              <Tag color='blue'>{"🗃️"}</Tag>
              <span style={{ fontWeight: 700 }}>{parsing.batchName}</span>
            </Flex>
          </Link>
        );
      else
        return (
          <Link to={`/content-sources/parsing/${parsing.id}`}>
            <Flex justify='start' align='center' gap={4}>
              <Tag color='orange'>{"📄"}</Tag>
              <span>
                {parsing?.article
                  ? parsing.article.title
                  : (parsing as IParsing).pdfName || parsing.doi}
              </span>
            </Flex>
          </Link>
        );
    },
  },
  {
    title: "Status",
    dataIndex: ["meta", "status"],
    key: "status",
    render: (_, parsing) => <ParsingStatusTag parsing={parsing} />,
  },
  {
    title: "Source",
    key: "parsingSource",
    render: (_, parsing) =>
      parsing.doi ? (
        <Tooltip title={parsing.doi}>
          <a href={parsing.doi} rel='noreferrer' target='_blank'>
            <Space>
              <LinkOutlined />
              {"DOI"}
            </Space>
          </a>
        </Tooltip>
      ) : (
        <Space>
          <FilePdfOutlined />
          {"PDF"}
        </Space>
      ),
  },
  {
    title: "Article status",
    dataIndex: ["article", "meta", "status"],
    key: "articleStatus",
    render: (articleStatus: "draft" | "published", parsing: IParsing) =>
      parsing?.batchName &&
      !location.pathname.includes("batch") ? null : articleStatus ===
        "draft" ? (
        <Tag icon={<EyeInvisibleOutlined />} color='orange'>
          {"Draft"}
        </Tag>
      ) : articleStatus === "published" ? (
        <Tag icon={<EyeOutlined />} color='green'>
          {"Published"}
        </Tag>
      ) : null,
  },
  {
    title: "Creation date",
    dataIndex: "meta",
    key: "meta",
    render: (meta: {
      creationDate: Date;
      lastModified: Date;
      status: "draft" | "published";
    }) => {
      return new Date(meta.creationDate).toLocaleDateString();
    },
  },
];

export default columns;
