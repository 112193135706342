import { useCallback, useState } from "react";
import { useNectarContext } from "../nectars/use-nectar-context";
import { getNectarQuestionList } from "~/services";
import { IQuery } from "~/model/query.model";
import { notification } from "antd";
import { getRequestErrorMessage } from "~/utils/helpers";

export const useQuestionsQuery = () => {
  const { setQuestions } = useNectarContext();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const fetch = useCallback(
    async (params: IQuery) => {
      try {
        setLoading(true);
        const res = await getNectarQuestionList(params);
        setQuestions(res);
      } catch (error) {
        setError(true);
        notification.error({
          message: "Failed to fetch questions",
          description: getRequestErrorMessage(error),
        });
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return { loading, error, fetch };
};

getNectarQuestionList;
