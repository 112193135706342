import { useContext, useMemo } from "react";

import FiltersDrawer from "../shared/FiltersDrawer";
import { IQuery } from "~/model/query.model";
import { Form, Select } from "antd";
import { searchFilterOptions } from "~/utils/helpers";
import { GlobalContext } from "~/context/global.context";
import { useNectarContext } from "~/hooks/nectars/use-nectar-context";

type NectarFilterDrawerProps = {
  defaultValues?: IQuery;
  onChange: (values: IQuery) => void;
};

export const NectarFilterDrawer = ({
  defaultValues,
  onChange,
}: NectarFilterDrawerProps) => {
  const { activeTab } = useNectarContext();
  const { organisationList } = useContext(GlobalContext);

  const showQuestions = activeTab === "questions";

  const MemoizedTableFilters = useMemo(
    () => (
      <FiltersDrawer
        defaultValues={defaultValues}
        fields={
          ["languages"].concat(
            showQuestions
              ? []
              : [
                  "owners",
                  "status",
                  "rooms",
                  "search",
                  "isPublic",
                  "medicalSpecialties",
                  "tags",
                ]
          ) as unknown as string[]
        }
        onChange={onChange}
      >
        {showQuestions ? (
          <Form.Item name='organisations'>
            <Select
              filterOption={searchFilterOptions}
              options={organisationList.map((org) => ({
                label: org.name,
                value: org._id,
              }))}
              mode='multiple'
              placeholder='Filter by organisations'
            />
          </Form.Item>
        ) : null}
      </FiltersDrawer>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showQuestions, organisationList]
  );

  return <div>{MemoizedTableFilters}</div>;
};
