import React, { createContext } from "react";
import { PaginatedResponse } from "~/model/api";
import { INectar, INectarQuestion } from "~/model/nectar.model";
import { LocalStorageKeys } from "~/utils/constants";

export type NectarActiveTab =
  | "ask-llm"
  | "questions"
  | "nectars"
  | "highlights";

type NectarContextType = {
  questions: PaginatedResponse<INectarQuestion> | null;
  setQuestions: (questions: PaginatedResponse<INectarQuestion> | null) => void;

  nectars: PaginatedResponse<INectar> | null;
  setNectars: (nectars: PaginatedResponse<INectar> | null) => void;

  activeTab: NectarActiveTab;
  setActiveTab: (activeTab: NectarActiveTab) => void;
};

export const NectarContext = createContext<NectarContextType | null>(null);

export const NectarProvider = ({ children }: { children: React.ReactNode }) => {
  const [activeTab, setActiveTab] = React.useState<NectarActiveTab>(
    (localStorage.getItem(
      LocalStorageKeys.NECTAR_ACTIVE_TAB
    ) as NectarActiveTab) || "ask-llm"
  );
  const [nectars, setNectars] =
    React.useState<PaginatedResponse<INectar> | null>(null);
  const [questions, setQuestions] =
    React.useState<PaginatedResponse<INectarQuestion> | null>(null);

  return (
    <NectarContext.Provider
      value={{
        questions,
        setQuestions,
        nectars,
        setNectars,
        activeTab,
        setActiveTab,
      }}
    >
      {children}
    </NectarContext.Provider>
  );
};
