import { TagsOutlined } from "@ant-design/icons";
import { Form, FormInstance, message, Select } from "antd";
import { useContext } from "react";
import { GlobalContext } from "~/context/global.context";
import { ITag } from "~/model/tag.model";
import { searchFilterOptions } from "~/utils/helpers";

type SelectTagsFieldProps = {
  form: FormInstance;
  mapKey?: keyof ITag;
  medicalSpecialtyKey?: string;
};

export default function SelectTagsField({
  form,
  mapKey = "_id",
  medicalSpecialtyKey = "medical_specialties",
}: SelectTagsFieldProps) {
  const { tagList } = useContext(GlobalContext);
  const values = Form.useWatch("tags", form);

  function handleSelect(value: string) {
    const tag = tagList.find((tag) => String(tag[mapKey]) === value);

    const medicalSpecialties = form.getFieldValue(medicalSpecialtyKey) || [];

    if (
      (medicalSpecialties.length > 0 &&
        !medicalSpecialties.includes(String(tag?.parent?.[mapKey]))) ||
      !medicalSpecialties.length
    ) {
      form.setFieldsValue({
        [medicalSpecialtyKey]: [
          ...medicalSpecialties,
          String(tag?.parent?.[mapKey]),
        ],
      });
      message.success({
        icon: <TagsOutlined />,
        content: `Added ${tag?.parent?.translations.en} to Medical Specialties`,
      });
    }
  }

  const options = tagList
    .filter((tag) => Object.keys(tag)?.includes("parent"))
    .map((tag) => ({
      label: (
        <span>
          {tag.translations.en}{" "}
          <small>{`(${tag?.parent?.translations.en})`}</small>
        </span>
      ),
      value: String(tag[mapKey]),
      name: `${tag.translations.en} ${tag?.parent?.translations.en}`,
    }));

  return (
    <Select
      mode='multiple'
      placeholder='Tags'
      options={options}
      value={values}
      filterOption={searchFilterOptions}
      onSelect={handleSelect}
      onChange={(value) => form.setFieldsValue({ tags: value })}
    />
  );
}
