import { useEffect, useState } from "react";
import { PaginatedResponse } from "~/model/api";
import { IRedirect } from "~/model/redirect.model";
import { getRedirectsList } from "~/services";

export const useShortLinksQuery = ({ offset, limit, tags }: { offset?: number, limit?: number, tags?:string[] } = {}) => {
  const [response, setResponse] = useState<PaginatedResponse<IRedirect> | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetch = async () => {
      try {
        setError(null);
        setLoading(true);
        const res = await getRedirectsList({ offset, limit, tags });
        setResponse(res);
      } catch (error) {
        setError(error as string);
      } finally {
        setLoading(false);
      }
    };
    fetch();
  }, [offset, limit, tags]);

  return { data: response?.docs, meta: response?.meta, loading, error };
};
